import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { Header, Form } from "./components";
import { Create, Edit } from "react-admin";

const CreateComponent = props => (
	<Card>
		<Header title="Create"></Header>
		<Create actions={<></>} {...props} title={<>Create</>} component={Grid}>
			<Form></Form>
		</Create>
	</Card>
);

const EditComponent = props => {
	return (
		<Card>
			<Header title="Edit"></Header>
			<Edit actions={<></>} {...props} title="Edit" component={Grid}>
				<Form></Form>
			</Edit>
		</Card>
	);
};
export { CreateComponent, EditComponent };
