import { curry } from "ramda";

export default ({ data, onSelect }) => {
	const onClick = curry((image, noop) => {
		onSelect(image);
	});
	return (
		<>
			{data.images &&
				data.images.data.map(i => (
					<>
						<img
							src={i.thumbnailUrl}
							style={{
								maxWidth: 200,
								maxHeight: 200,
								border: "1px solid #efefef",
								padding: "10px",
								margin: "20px",
								cursor: "pointer"
							}}
							onClick={onClick({
								imageUrl: i.thumbnailUrl
							})}
						/>
					</>
				))}
			{data.systembolaget &&
				data.systembolaget.data.map(i => {
					const imageUrl =
						i.images &&
						i.images[0] &&
						`${i.images[0].imageUrl}_200.png`;

					return (
						<>
							<div
								style={{
									marginBottom: "15px",
									padding: "10px",
									background: "#efefef",
									cursor: "pointer"
								}}
								onClick={onClick({
									imageUrl,
									...(i.taste &&
										i.usage && {
											description: `${i.taste} ${i.usage}`
										})
								})}
							>
								<div>
									<img
										src={imageUrl}
										style={{
											maxWidth: 200,
											maxHeight: 200
										}}
									/>
								</div>
								<div>
									{i.taste} {i.usage}
								</div>
							</div>
						</>
					);
				})}
		</>
	);
};
