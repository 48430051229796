import EuroIcon from "@material-ui/icons/Euro";
import List from "./List";
import Show from "./Show";

export default {
	name: "integration-functions",
	idPropName: "integrationFunctionId",
	icon: EuroIcon,
	list: List,
	show: Show
};
