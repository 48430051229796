import env from "env";
import { getUserIdToken } from "modules/auth";
import restDataProvider from "ra-data-rest-client";
import { assocPath, is, path, prop } from "ramda";
import { fetchUtils } from "react-admin";

const fetchJson = async (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}

	const userIdToken = await getUserIdToken();
	options.headers.set("authorization", `bearer ${userIdToken}`);

	return fetchUtils.fetchJson(url, options);
};

const functionsMultipartFormCall = async (path, file, params) => {
	const functionsUrl = env.functionsUrl;
	const url = `${functionsUrl}${path}`;
	if (!file) throw new Error("Missing file");

	const formData = new FormData();
	if (is(Object, params))
		for (let param in params) {
			formData.append(param, params[param]);
		}

	formData.append("file", file);

	const result = await fetch(url, {
		method: "POST",
		body: formData
	})
		.then(response => response.json())
		.then(response => response.result);

	return result;
};

const Provider = idPropMap => {
	const rdp = restDataProvider(env.apiUrl, idPropMap, {}, fetchJson);

	const imageUploadHandler = action => async (resource, params) => {
		const largeFile = path(["data", "image", "large", "rawFile"], params);
		const smallFile = path(["data", "image", "small", "rawFile"], params);
		const largeCoverFile = path(
			["data", "coverImage", "large", "rawFile"],
			params
		);
		const smallCoverFile = path(
			["data", "coverImage", "small", "rawFile"],
			params
		);

		if (largeFile) {
			const imageUrl = await functionsMultipartFormCall(
				"/uploadPhoto",
				largeFile,
				{
					width: 180,
					height: 180,
					folder: "store-images",
					strategy: "cover",
					quality: 80
				}
			).then(prop("publicUrl"));

			params = assocPath(["data", "image", "large"], imageUrl, params);
		}

		if (smallFile) {
			const imageUrl = await functionsMultipartFormCall(
				"/uploadPhoto",
				smallFile,
				{
					width: 98,
					height: 98,
					folder: "store-images",
					strategy: "cover",
					quality: 80
				}
			).then(prop("publicUrl"));

			params = assocPath(["data", "image", "small"], imageUrl, params);
		}

		if (largeCoverFile) {
			const imageUrl = await functionsMultipartFormCall(
				"/uploadPhoto",
				largeCoverFile,
				{
					width: 1200,
					height: 450,
					folder: "store-images",
					strategy: "cover",
					quality: 80
				}
			).then(prop("publicUrl"));

			params = assocPath(
				["data", "coverImage", "large"],
				imageUrl,
				params
			);
		}

		if (smallCoverFile) {
			const imageUrl = await functionsMultipartFormCall(
				"/uploadPhoto",
				smallCoverFile,
				{
					width: 640,
					height: 360,
					folder: "store-images",
					strategy: "cover",
					quality: 80
				}
			).then(prop("publicUrl"));

			params = assocPath(
				["data", "coverImage", "small"],
				imageUrl,
				params
			);
		}

		return action(resource, params);
	};

	return {
		...rdp,
		update: imageUploadHandler(rdp.update),
		create: imageUploadHandler(rdp.create)
	};
};

export default Provider;
