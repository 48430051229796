import { Grid } from "@material-ui/core";
import React from "react";

export default props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);
