import { Button, useMediaQuery } from "@material-ui/core";
import { Confirm } from "ra-ui-materialui";
import { pick } from "ramda";
import { useState } from "react";
import { useMutation, useNotify, useRecordContext } from "react-admin";

const ActionButton = ({
	actionId,
	action,
	actionBody = {},
	bodyFields = [],
	resource,
	visible,
	successMessage = "Success!",
	confirmTitle,
	mobileIcon,
	confirmBody,
	cancelButtonText = "Cancel",
	confirmButtonText = "Yes",
	...props
}) => {
	const record = useRecordContext(props);
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	if (visible && !visible(record)) return <></>;
	const [open, setOpen] = useState(false);
	const notify = useNotify();
	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const [approve, { loading }] = useMutation(
		{
			type: "update",
			resource: resource,
			payload: {
				id: actionId,
				data: { action, ...pick(bodyFields, record), ...actionBody }
			}
		},
		{
			onSuccess: ({ data }) => {
				const { success } = data;

				if (success) {
					const message =
						typeof successMessage === "function"
							? successMessage(data)
							: successMessage;
					notify(`${message}`);
				} else {
					onFailure: error =>
						notify(`Error ${error.message}`, "warning");
				}
			},
			onFailure: error => notify(`Error ${error.message}`, "warning")
		}
	);

	const handleConfirm = () => {
		approve();
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleClick} color="primary">
				{isXSmall && mobileIcon ? mobileIcon : props.children}
			</Button>
			<Confirm
				isOpen={open}
				loading={loading}
				title={confirmTitle}
				content={confirmBody}
				confirm={confirmButtonText}
				cancel={cancelButtonText}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default ActionButton;
