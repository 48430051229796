import Show from "./Show";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { CreateComponent, EditComponent } from "./CreateEdit";
import List from "./List";

export default {
	name: "payment-providers",
	idPropName: "paymentProviderId",
	show: Show,
	options: {
		label: "Payment Providers"
	},
	icon: CreditCardIcon,
	create: CreateComponent,
	edit: EditComponent,
	list: List
};
