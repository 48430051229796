import queryString from "query-string";
import React from "react";
import { BooleanInput, SimpleForm, TextInput, Toolbar } from "react-admin";

const Component = props => {
	const { storeId } = props.location
		? queryString.parse(props.location.search)
		: {};

	const initialValues = state => {
		return {
			storeId: storeId || state.storeId,
			active: typeof state.active === "boolean" ? state.active : true
		};
	};

	return (
		<SimpleForm
			toolbar={<Toolbar alwaysEnableSaveButton />}
			initialValues={initialValues}
			{...props}
			redirect="show"
		>
			<TextInput
				style={{ marginTop: 0 }}
				source="tableId"
				helperText="E.g: 1"
				margin="normal"
				allowEmpty={true}
				emptyValue=""
			/>
			<TextInput
				style={{ marginTop: 0 }}
				source="externalTableId"
				helperText="Ex: (PreSaleTransactionParkingId)"
				margin="normal"
				allowEmpty={true}
				emptyValue=""
			/>
			<BooleanInput
				style={{ marginTop: 0 }}
				source="active"
				margin="normal"
				allowEmpty={true}
			/>
		</SimpleForm>
	);
};

export default Component;
