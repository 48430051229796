import { Card, CardHeader } from "@material-ui/core";
import { Create, Edit } from "ra-ui-materialui";
import React from "react";
import { Form } from "./components";

const CreateComponent = props => {
	return (
		<Card>
			<CardHeader title="Create Integration"></CardHeader>
			<Create
				redirect=""
				mutationMode="pessimistic"
				actions={false}
				{...props}
			>
				{<Form {...props} type="create" />}
			</Create>
		</Card>
	);
};

const EditComponent = props => {
	return (
		<Card>
			<CardHeader title="Edit"></CardHeader>
			<Edit mutationMode="pessimistic" actions={false} {...props}>
				<Form {...props} type="edit" />
			</Edit>
		</Card>
	);
};

export { CreateComponent, EditComponent };
