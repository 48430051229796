import React, { createElement } from "react";
import {
	ReferenceManyField,
	Datagrid,
	TextField,
	useRecordContext,
	DateField,
	SimpleList,
	NumberField
} from "react-admin";
import { Card, CardHeader, useMediaQuery } from "@material-ui/core";
import Moment from "moment-timezone";

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => "Status: " + record.status}
		secondaryText={record =>
			(record.amount || "0") +
			" SEK, " +
			"Exported: " +
			(record.timeExported
				? Moment(record.timeExported)
						.tz("Europe/Stockholm")
						.format("YYYY-MM-DD HH:mm")
				: "No")
		}
		tertiaryText={record =>
			"Arrival date: " +
			Moment(record.ArrivalDate)
				.tz("Europe/Stockholm")
				.format("YYYY-MM-DD")
		}
	></SimpleList>
);

const PostPanel = ({ id, record, resource, parentRecord }) => (
	<ReferenceManyField
		label="Fortnox reports"
		reference="stripe-payout-details"
		target="stripePayoutId"
		filter={{ merchantId: parentRecord.id }}
	>
		<Datagrid>
			<TextField label="Order ID" source="id" emptyText="-" />
			<DateField
				label="Transaction date"
				source="transactionDate"
				emptyText="-"
			/>
			<DateField
				label="Settled on merchant account"
				source="settledOnMerchantAccount"
				emptyText="-"
			/>

			<DateField
				label="Time exported"
				source="timeExported"
				emptyText="-"
			/>
			<TextField label="Provider" source="provider" emptyText="-" />
			<NumberField
				label="Stripe payout amount"
				source="payoutAmount"
				emptyText="-"
			/>
			<NumberField
				label="Stripe payout fee"
				source="payoutFee"
				emptyText="-"
			/>
		</Datagrid>
	</ReferenceManyField>
);

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader title="Stripe payouts"></CardHeader>
			<ReferenceManyField
				label="Stripe payouts"
				reference="stripe-payouts"
				target="merchantId"
			>
				{isXSmall ? (
					<MobileGrid />
				) : (
					<Datagrid
						{...props}
						expand={data =>
							PostPanel({ ...data, parentRecord: record })
						}
					>
						<TextField
							label="Status"
							source="status"
							emptyText="-"
						/>
						<DateField
							label="Bookkeeping date"
							source="bookkeepingDate"
							emptyText="-"
						/>
						<DateField
							label="Arrival date"
							source="arrivalDate"
							emptyText="-"
						/>
						<NumberField
							label="Amount"
							source="amount"
							emptyText="0"
						/>
						<NumberField label="Fee" source="fee" emptyText="0" />
						<TextField
							label="Destination account"
							source="destination"
							emptyText="0"
						/>
						<DateField
							label="Created"
							source="created"
							emptyText="-"
						/>
						<DateField
							label="Paid"
							source="timePaid"
							emptyText="-"
						/>
						<DateField
							label="Exported"
							source="timeExported"
							emptyText="-"
						/>
					</Datagrid>
				)}
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
