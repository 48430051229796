import React from "react";
import { useRecordContext } from "react-admin";

const GoogleMapsField = props => {
	const { source } = props;
	const record = useRecordContext(props);

	if (!record[source]) return "N/A";
	const address = `https://maps.googleapis.com/maps/api/staticmap?center=${
		record.address_street + " " + record.address_county
	}&zoom=13&size=600x300&maptype=roadmap
	&markers=color:red%7C${record.address_street + " " + record.address_county}
	&key=AIzaSyADqgAsFC6rHVigZHxTHkSf9t7emU1XXyc`;
	return <img src={address} />;
};
const Component = props => {
	const record = useRecordContext();
	return <GoogleMapsField source="address_street" />;
};

export default Component;
