import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import React from "react";
import {
	Datagrid,
	DateField,
	Filter,
	FunctionField,
	List,
	ReferenceField,
	SimpleList,
	TextField,
	TextInput,
	TopToolbar
} from "react-admin";
import FilterSidebar from "./FilterSidebar";
import rowStyle from "./RowStyle";

const useListStyles = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid"
	},
	headerCell: {
		padding: "6px 8px 6px 8px"
	},
	rowCell: {
		padding: "6px 8px 6px 8px"
	},
	comment: {
		maxWidth: "18em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	}
});

const DesktopGrid = ({ selectedRow, ...props }) => {
	const classes = useListStyles();
	return (
		<Datagrid
			{...props}
			isRowSelectable={() => false}
			rowClick="show"
			rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell
			}}
		>
			<TextField source="id" emptyText="-" />
			<DateField showTime={true} source="time_created" />
			<TextField source="store_name" emptyText="-" />
			<TextField label="Type" source="type" />
			<TextField label="Status" source="status" />
			<ReferenceField
				source="checkin_id"
				reference="checkins"
				link="show"
				emptyText="-"
				label="Checkin"
			>
				<FunctionField
					render={record => (record.label ? record.label : "#")}
				/>
			</ReferenceField>
			<TextField label="Total" source="total" />
			<TextField label="Tip" source="tip" emptyText="-" />
			<TextField label="Discount" source="discount_total" emptyText="-" />
		</Datagrid>
	);
};

const OrderTopFilters = props => {
	return (
		<Filter {...props}>
			<TextInput autoFocus={true} label="Order ID" source="id" alwaysOn />
		</Filter>
	);
};

const MobileGrid = props => (
	<SimpleList
		{...props}
		filters={<OrderTopFilters />}
		primaryText={record => record.store_name}
		secondaryText={record =>
			`${moment(record.time_created)
				.tz("Europe/Stockholm")
				.format("YYYY-MM-DD HH:mm:ss")} - ${record.id}`
		}
		tertiaryText={record => `${record.total}`}
		linkType="show"
		rowStyle={record => {
			if (record.status === "COMPLETED") return { color: "green" };
			if (record.status === "PENDING") return { color: "orange" };

			return { color: "red" };
		}}
	/>
);

const ListActions = () => <TopToolbar></TopToolbar>;

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	return (
		<List
			{...props}
			actions={<ListActions />}
			aside={<FilterSidebar />}
			filters={<OrderTopFilters />}
			bulkActionButtons={false}
			perPage={25}
			sort={{ field: "time_created", order: "DESC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
