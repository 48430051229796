import { Grid } from "@material-ui/core";
import env from "env";
import React from "react";
import {
	DateField,
	FunctionField,
	ReferenceField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField
} from "react-admin";

const getWelcomeLink = welcomeTokenId =>
	`${env.dashboardUrl}/welcome/${welcomeTokenId}`;
export default props => {
	return (
		<Show {...props} actions={<></>} title="Welcome Token">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<TabbedShowLayout>
						<Tab label="Welcome Email">
							<TextField label="ID" source="id" />
							<ReferenceField
								source="uid"
								label="User"
								reference="dashboard-users"
								link="show"
							>
								<TextField source="useremail" emptyText="-" />
							</ReferenceField>
							<DateField source="timeCreated" showTime={true} />
							<DateField source="timeConsumed" showTime={true} />
							<DateField
								source="timeExpiration"
								showTime={true}
							/>
						</Tab>
						<Tab label="LINK">
							<FunctionField
								label="URL"
								addLabel={false}
								render={record => (
									<a href={getWelcomeLink(record.id)}>
										{getWelcomeLink(record.id)}
									</a>
								)}
							/>
						</Tab>
					</TabbedShowLayout>
				</Grid>
			</>
		</Show>
	);
};
