import { HotTable } from "@handsontable/react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormatColorFill, GetApp } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import jsonExport from "jsonexport/dist";
import Papa from "papaparse";
import { curry, zipObj } from "ramda";
import React, { createRef, useCallback, useState } from "react";
import {
	BooleanInput,
	Confirm,
	downloadCSV,
	FileField,
	FileInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	Toolbar,
	useDataProvider,
	useInput,
	useMutation,
	useNotify,
	useRecordContext,
	useRedirect
} from "react-admin";
import { useFormState } from "react-final-form";
import "styles/css/handsontable.css";
import Products from "./Products";

const PRODUCT_CSV_HEADERS = [
	"station",
	"menuSection",
	"productId",
	"name",
	"description",
	"price",
	"vatRate",
	"imageUrl"
];

const CSVWarning = props => {
	const { values } = useFormState();
	const csv = values.csv;
	if (!csv) return <></>;
	const warnings = [];

	for (let c of PRODUCT_CSV_HEADERS) {
		if (!csv.fields.includes(c)) warnings.push(`Missing column ${c}.`);
	}

	if (warnings.length === 0) return <></>;

	return (
		<>
			<Alert fullWidth={true} severity="warning">
				{warnings.map(w => (
					<>
						{w}
						<br />
					</>
				))}
			</Alert>
			<br />
		</>
	);
};

const CSVInput = props => {
	const {
		input: { onChange }
	} = useInput(props);

	const [productModalOpen, setProductModalOpen] = React.useState(false);
	const [productModalContent, setProductModalContent] = React.useState(<></>);

	const handleProductModalClose = () => {
		setProductModalOpen(false);
	};

	const onProductScrape = async (type, row, product) => {
		dataProvider
			.getOne("products", {
				id: `1?t=${type}&q=${encodeURIComponent(
					(product.name || "").split(",")[0]
				)}`
			})
			.then(({ data: d }) => {
				setProductModalContent(
					<Products
						data={d}
						onSelect={select => {
							const newRows = [...data.rows];
							if (select && select.imageUrl) {
								newRows[row].imageUrl = select.imageUrl;
							}
							if (select && select.description) {
								newRows[row].description = select.description;
							}
							const newData = {
								...data,
								rows: newRows
							};
							setData(newData);
							onChange(newData);
							setProductModalOpen(false);
						}}
					/>
				);
				setProductModalOpen(true);
			});
	};

	const [data, setData] = useState({
		fields: PRODUCT_CSV_HEADERS,
		rows: [PRODUCT_CSV_HEADERS.map(h => "")]
	});
	const dataProvider = useDataProvider();

	const DownloadProducts = props => {
		const { values } = useFormState();

		const downloadProducts = curry((action, u) => {
			dataProvider
				.getOne("menus", {
					id: `1?type=csv&merchantId=${values.merchantId}&storeId=${values.storeId}&priceListId=${values.priceListId}`,
					type: "csv"
				})
				.then(({ data }) => {
					if (action === "csv") {
						jsonExport(
							data.products,
							{
								headers: PRODUCT_CSV_HEADERS
							},
							(err, csv) => {
								downloadCSV(csv, "products");
							}
						);
					} else {
						setData({
							fields: PRODUCT_CSV_HEADERS,
							rows: data.products
						});
						onChange({
							fields: PRODUCT_CSV_HEADERS,
							rows: data.products
						});
					}
				});
		});

		return (
			<>
				<Button
					onClick={downloadProducts("fill")}
					variant="contained"
					color="default"
					startIcon={<FormatColorFill />}
				>
					Fill table with existing products
				</Button>
				&nbsp;&nbsp;
				<Button
					onClick={downloadProducts("csv")}
					variant="contained"
					color="default"
					startIcon={<GetApp />}
				>
					Download existing products csv
				</Button>
				<br />
				<br />
			</>
		);
	};

	const onFileInputChange = d => {
		Papa.parse(d, {
			header: true,
			complete: function (results) {
				const rows = results.data;
				const fields = results.meta.fields;
				let csv = {};
				const cleanUndefined = v => (v == "undefined" ? null : v);
				if (
					fields.includes("categoryPosition") &&
					fields.includes("absolutePosition")
				) {
					csv = {
						fields: PRODUCT_CSV_HEADERS,
						rows: rows
							.map(r => ({
								station: "KITCHEN",
								menuSection: cleanUndefined(r["category"]),
								productId: null,
								name: cleanUndefined(r["product"]),
								description: cleanUndefined(r["description"]),
								price: cleanUndefined(r["price"]),
								vatRate: 0.12,
								imageUrl: cleanUndefined(r["image"])
							}))
							.filter(r => r.menuSection !== "Utvalt åt dig")
					};
				} else {
					csv = {
						fields,
						rows
					};
				}

				setData(csv);
				onChange(csv);
			}
		});
	};

	const hotTableRef = createRef();

	const hotSettings = {
		manualColumnMove: true,
		multiColumnSorting: {
			indicator: true
		},
		manualColumnResize: true,
		contextMenu: {
			items: {
				row_above: {},
				row_below: {},
				remove_row: {},
				"---------": {},
				images: {
					// Own custom option
					name() {
						return "Images"; // Name can contain HTML
					},
					callback(key, selection, clickEvent) {
						if (hotTableRef.current) {
							const newRows = hotTableRef.current.hotInstance
								.getData()
								.map(r => zipObj(PRODUCT_CSV_HEADERS, r));

							const row = selection[0].start.row;

							if (onProductScrape)
								onProductScrape("images", row, newRows[row]);
						}
					}
				},
				systembolaget: {
					// Own custom option
					name() {
						return "Systembolaget";
					},
					callback(key, selection, clickEvent) {
						if (hotTableRef.current) {
							const newRows = hotTableRef.current.hotInstance
								.getData()
								.map(r => zipObj(PRODUCT_CSV_HEADERS, r));

							const row = selection[0].start.row;

							if (onProductScrape)
								onProductScrape(
									"systembolaget",
									row,
									newRows[row]
								);
						}
					}
				}
			}
		}
	};

	const onModifyData = changes => {
		if (hotTableRef.current) {
			const newRows = hotTableRef.current.hotInstance
				.getData()
				.map(r => zipObj(data.fields, r));

			onChange({
				fields: data.fields,
				rows: newRows
			});
		}
	};

	return (
		<>
			<DownloadProducts />
			<FileInput
				source="csvData"
				label="Upload file (.csv)"
				multiple={false}
				onChange={onFileInputChange}
				accept="text/csv"
			>
				<FileField source="src" title="title" />
			</FileInput>
			<div>
				<Dialog
					open={productModalOpen}
					fullScreen={true}
					onClose={handleProductModalClose}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle id="responsive-dialog-title">
						Choose
					</DialogTitle>
					<DialogContent>{productModalContent}</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							onClick={handleProductModalClose}
							color="primary"
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<HotTable
				data={data.rows}
				settings={hotSettings}
				ref={hotTableRef}
				colHeaders={data.fields}
				columns={data.fields.map(f => ({
					data: f,
					readOnly: f === "productId"
				}))}
				afterChange={onModifyData}
				rowHeaders={true}
				width="auto"
				height="auto"
				licenseKey="non-commercial-and-evaluation"
			/>
		</>
	);
};

const Component = props => {
	const [mutate] = useMutation();
	const [open, setOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(true);
	const [loading, setLoading] = useState(false);
	const [confirmData, setConfirmData] = useState(null);
	const [confirmTitle, setConfirmTitle] = useState("Confirm import");
	const [confirmContent, setConfirmContent] = useState("content");
	const notify = useNotify();
	const redirect = useRedirect();
	const record = useRecordContext();
	const handleDialogClose = () => setOpen(false);

	const handleConfirm = () => {
		setLoading(true);
		dataProvider
			.update("menus", { id: null, data: { ...confirmData, dry: false } })
			.then(({ data }) => {
				notify(`Import was successful!`, "success");
				redirect(`/stores/${record.storeId}/show`);
			})
			.finally(() => {
				setLoading(false);
				setOpen(false);
			});
	};
	const dataProvider = useDataProvider();

	const save = useCallback(
		async values => {
			setConfirmData({ ...values, dry: false });
			dataProvider
				.update("menus", { id: null, data: { ...values, dry: true } })
				.then(({ data }) => {
					const willCreateMenu = !!data.createdMenu;
					const willCreateStations =
						data.createdStations && data.createdStations.length > 0;
					const willFetchImages = data.fetchedImages > 0;
					const willCreateProducts = data.createdProducts > 0;
					const willUpdateProducts = data.updatedProducts > 0;

					const noChanges =
						!willCreateMenu &&
						!willCreateStations &&
						!willFetchImages &&
						!willCreateProducts &&
						!willUpdateProducts;

					setConfirmContent(
						<>
							{noChanges ? (
								<>
									<Alert severity="info">
										No changes will be made
									</Alert>
									<br />
								</>
							) : (
								<>
									The following changes will be made: <br />
									<br />
								</>
							)}
							{willCreateMenu && (
								<>
									<Alert severity="info">
										The following menu will be
										created:&nbsp;
										<b>{data.createdMenu.menuName}</b> with
										the sections:&nbsp;
										<b>
											{data.createdMenu.sections.join(
												", "
											)}
										</b>
									</Alert>
									<br />
								</>
							)}
							{willCreateStations && (
								<>
									<Alert severity="info">
										The following stations will be created:{" "}
										<b>
											{data.createdStations
												.map(d => d.name)
												.join(", ")}
										</b>
									</Alert>
									<br />
								</>
							)}
							{willFetchImages && (
								<>
									<Alert severity="info">
										{data.fetchedImages} images will be
										fetched
									</Alert>
									<br />
								</>
							)}
							{willCreateProducts && (
								<>
									<Alert severity="info">
										{data.createdProducts} new products will
										be created
									</Alert>
									<br />
								</>
							)}
							{willUpdateProducts && (
								<>
									<Alert severity="info">
										{data.updatedProducts} products will be
										updated (including price/vat)
									</Alert>
									<br />
								</>
							)}
						</>
					);
					setOpen(true);
				})
				.catch(error => {
					console.error("THE ERROR", error);
					notify(JSON.stringify(error), "error");
				});
		},
		[mutate]
	);

	return (
		<SimpleForm
			toolbar={<Toolbar alwaysEnableSaveButton />}
			{...props}
			save={save}
			mutationMode="pessimistic"
			redirect="show"
		>
			<Confirm
				isOpen={open}
				title={confirmTitle}
				loading={loading}
				content={confirmContent}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
			<ReferenceInput
				label="Merchant"
				source="merchantId"
				reference="merchants"
				disabled={true}
				allowEmpty={false}
				sort={false}
				validate={required("Merchant is required")}
			>
				<SelectInput label="Store" optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				label="Store"
				source="storeId"
				reference="stores"
				disabled={true}
				allowEmpty={false}
				sort={false}
				validate={required("Store is required")}
			>
				<SelectInput label="Store" optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				label="Price List"
				source="priceListId"
				reference="priceLists"
				disabled={true}
				allowEmpty={false}
				sort={false}
				validate={required("Price List is required")}
			>
				<SelectInput label="Store" optionText="name" />
			</ReferenceInput>
			<BooleanInput
				style={{ display: "none" }}
				fullWidth={true}
				initialValue={true}
				helperText="When this setting is enabled a menu based on the column menuSection in the CSV will be created."
				source="createMenu"
				label="Create menu"
			/>
			<BooleanInput
				style={{ display: "none" }}
				fullWidth={true}
				initialValue={true}
				helperText="When this setting is enabled stations will be created and updated for the products."
				source="setStations"
				label="Set Stations"
			/>
			<br />
			<Alert fullWidth={true} severity="info">
				Can be a csv from <a href="https://dataeats.co/">dataeats.co</a>{" "}
				or a csv with columns: {PRODUCT_CSV_HEADERS.join(", ")}
			</Alert>
			<br />
			<CSVInput source="csv" />
			<br />
			<CSVWarning />
		</SimpleForm>
	);
};

export default Component;
