import EmptyCard from "components/EmptyCard";
import {
	Card,
	Button,
	CardHeader,
	Grid,
	useMediaQuery
} from "@material-ui/core";
import {
	Show,
	TextField,
	DateField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	BooleanField,
	Confirm
} from "ra-ui-materialui";
import { VisibilityOff, Visibility, VpnKey } from "@material-ui/icons";
import { createElement, useState } from "react";

import {
	TabbedShowLayout,
	Tab,
	ArrayField,
	SimpleList,
	useRefresh,
	ReferenceManyField,
	Datagrid,
	useNotify,
	useMutation,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";
import { Title } from "./components";

const Container = props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);

const ActionButton = ({
	actionId,
	action,
	mobileIcon,
	successMessage = "Success!",
	confirmTitle,
	confirmBody,
	cancelButtonText = "Cancel",
	confirmButtonText = "Yes",
	...props
}) => {
	const [open, setOpen] = useState(false);
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	const notify = useNotify();
	const handleClick = () => setOpen(true);
	const handleDialogClose = () => setOpen(false);

	const [approve, { loading }] = useMutation(
		{
			type: "update",

			resource: "dashboard-users",
			payload: { id: actionId, data: { action } }
		},
		{
			onSuccess: ({ data }) => {
				notify("Success!");
			},
			onFailure: error => notify(error, "warning")
		}
	);

	const handleConfirm = () => {
		approve();
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleClick} color="primary">
				{isXSmall && mobileIcon ? mobileIcon : props.children}
			</Button>
			<Confirm
				isOpen={open}
				loading={loading}
				title={confirmTitle}
				content={confirmBody}
				confirm={confirmButtonText}
				cancel={cancelButtonText}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
			/>
		</>
	);
};

const Buttons = props => {
	const record = useRecordContext(props);
	return (
		<>
			{record["active"] && (
				<ActionButton
					action="disable"
					mobileIcon={<VisibilityOff />}
					successMessage="User have been disabled!"
					actionId={record.id}
					actionBody={{}}
					confirmTitle="Disable User"
					confirmBody="Are you sure you want to disable this user?"
				>
					Disable User
				</ActionButton>
			)}
			{!record["active"] && (
				<ActionButton
					action="enable"
					mobileIcon={<Visibility />}
					successMessage="User have been enabled!"
					actionId={record.id}
					actionBody={{}}
					confirmTitle="Enable User"
					confirmBody="Are you sure you want to enable this user?"
				>
					Enable User
				</ActionButton>
			)}
			<ActionButton
				action="sendWelcomeEmail"
				mobileIcon={<Visibility />}
				successMessage="Welcome email has been sent"
				actionId={record.id}
				actionBody={{}}
				confirmTitle="Send welcome email"
				confirmBody="Are you sure you want to send welcome email? This will expire all other welcome emails."
			>
				Send welcome email
			</ActionButton>
		</>
	);
};

const CardHeaderTitle = props => {
	const record = useRecordContext(props);

	return <div>{record?.useremail}</div>;
};
export default props => {
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	return (
		<Show {...props} component={Container} title={<Title />}>
			<>
				<Grid item xs={12} md={12} lg={12} xl={6}>
					<Card>
						<CardHeader
							title={<CardHeaderTitle {...props} />}
							action={<Buttons />}
						></CardHeader>
						<TabbedShowLayout>
							<Tab label="Details">
								<TextField source="id" emptyText="-" />
								<TextField
									label="Email"
									source="useremail"
									emptyText="-"
								/>
								<DateField
									label="Create Date"
									showTime={true}
									source="createDate"
									emptyText="-"
								/>
								<DateField
									label="Last Sign in"
									showTime={true}
									source="lastSignInDate"
									emptyText="-"
								/>
								<ReferenceArrayField
									label="Merchants"
									reference="merchants"
									source="merchantIds"
								>
									<SingleFieldList>
										<ChipField source="name" />
									</SingleFieldList>
								</ReferenceArrayField>

								<BooleanField source="active" />
								<BooleanField source="emailVerified" />
								<BooleanField source="superuser" />
							</Tab>
						</TabbedShowLayout>
					</Card>
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={6}>
					<Card>
						<CardHeader title="Welcome Emails"></CardHeader>
						<ReferenceManyField
							label="Id"
							reference="welcome-tokens"
							target="uid"
							sort={{ field: "timeCreated", order: "DESC" }}
						>
							{isXSmall ? (
								<SimpleList
									{...props}
									primaryText={p => p.id}
									secondaryText={p => p.email}
									linkType="show"
								></SimpleList>
							) : (
								<Datagrid
									{...props}
									empty={
										<EmptyCard text="No welcome emails sent yet" />
									}
									rowClick="show"
								>
									<TextField
										source="id"
										label="Token"
										emptyText="-"
									/>
									<TextField
										source="email"
										label="Email"
										emptyText="-"
									/>
									<DateField
										source="timeCreated"
										label="Created"
										emptyText="-"
										showTime={true}
									/>
									<DateField
										source="timeConsumed"
										label="Consumed"
										showTime={true}
										emptyText="-"
									/>
									<DateField
										source="timeExpiration"
										label="Expiring"
										showTime={true}
										emptyText="-"
									/>
								</Datagrid>
							)}
						</ReferenceManyField>
					</Card>
				</Grid>
			</>
		</Show>
	);
};
