import { Grid } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import React from "react";
import {
	BooleanField,
	DateField,
	FunctionField,
	ReferenceField,
	ReferenceManyField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField
} from "react-admin";

const OrderTicketDetails = ({ items }) => {
	if (!items) return null;

	return (
		<>
			<Table
				sx={{ minWidth: 650 }}
				size="small"
				aria-label="a dense table"
			>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="right">Quantity</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, ix) => (
						<TableRow
							key={ix}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0
								}
							}}
						>
							<TableCell component="th" scope="row">
								{item.productName}
							</TableCell>
							<TableCell align="right">{item.quantity}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<br />
		</>
	);
};

export default props => {
	return (
		<Show {...props} actions={<></>} title="Order Ticket">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<TabbedShowLayout>
						<Tab label="Order Ticket Export">
							<ReferenceField
								link="show"
								label="Order Ticket"
								source="id"
								reference="ordertickets"
							>
								<TextField source="id" />
							</ReferenceField>
							<DateField
								label="Started"
								source="timeStarted"
								showTime={true}
							/>
							<DateField
								label="Completed"
								source="timeCompleted"
								showTime={true}
							/>
							<DateField
								label="Error"
								source="timeError"
								showTime={true}
							/>
							<TextField label="Status" source="status" />
							<TextField label="Error" source="error" />
						</Tab>
					</TabbedShowLayout>
				</Grid>
			</>
		</Show>
	);
};
