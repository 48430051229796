import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { useTheme } from "@material-ui/core/styles";

const rowStyle = selectedRow => record => {
	const theme = useTheme();
	let style = {};
	if (!record) {
		return style;
	}
	if (selectedRow && selectedRow === record.id) {
		style = {
			...style,
			backgroundColor: theme.palette.action.selected
		};
	}
	if (record.status === "COMPLETED")
		return {
			...style,
			borderLeftColor: green[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (record.status === "PENDING")
		return {
			...style,
			borderLeftColor: orange[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (record.status === "FAILED")
		return {
			...style,
			borderLeftColor: red[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	return style;
};

export default rowStyle;
