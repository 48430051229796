import { cartItemsConfig } from "modules/cart-items";
import { cartsConfig } from "modules/carts";
import { checkinGroupConfig } from "modules/checkin-groups";
import { checkinsConfig } from "modules/checkins";
import { companyConfig } from "modules/companies";
import { dashboardUserFunctionsConfig } from "modules/dashboard-user-functions";
import { dashboardUsersConfig } from "modules/dashboard-users";
import { dealConfig } from "modules/deals";
import { fortnoxReportDetailsConfig } from "modules/fortnox-report-details";
import { fortnoxReportsConfig } from "modules/fortnox-reports";
import { integrationFunctionsConfig } from "modules/integration-functions";
import { integrationLogsConfig } from "modules/integration-logs";
import { integrationSchemaConfig } from "modules/integration-schema";
import { integrationsConfig } from "modules/integrations";
import { menuConfig } from "modules/menus";
import { merchantConfig } from "modules/merchants";
import { orderExportConfig } from "modules/orderexports";
import { orderItemConfig } from "modules/orderitems";
import { ordersConfig } from "modules/orders";
import { orderStatusTransitionsConfig } from "modules/orderstatustransitions";
import { orderTicketExportConfig } from "modules/orderticketexports";
import { orderTicketConfig } from "modules/ordertickets";
import { paymentMethodConfig } from "modules/payment-methods";
import { paymentProviderConfig } from "modules/payment-providers";
import { paymentConfig } from "modules/payments";
import { paymentStatusTransitionsConfig } from "modules/paymentstatustransitions";
import { priceListConfig } from "modules/price-lists";
import { productConfig } from "modules/products";
import { restaurantConfig } from "modules/restaurants";
import { stationConfig } from "modules/stations";
import { storesConfig } from "modules/stores";
import { stripePayoutDetailsConfig } from "modules/stripe-payout-details";
import { stripePayoutsConfig } from "modules/stripe-payouts";
import { userConfig } from "modules/users";
import { welcomeTokensConfig } from "modules/welcome-tokens";
import { resourcesToIdPropNameMap } from "utils";
const resources = [
	merchantConfig,
	storesConfig,
	priceListConfig,
	checkinsConfig,
	integrationsConfig,
	integrationFunctionsConfig,
	integrationLogsConfig,
	integrationSchemaConfig,
	dashboardUsersConfig,
	stationConfig,
	paymentProviderConfig,
	fortnoxReportsConfig,
	fortnoxReportDetailsConfig,
	stripePayoutsConfig,
	stripePayoutDetailsConfig,
	dashboardUserFunctionsConfig,
	dealConfig,
	menuConfig,
	ordersConfig,
	orderItemConfig,
	orderTicketConfig,
	orderExportConfig,
	paymentConfig,
	userConfig,
	companyConfig,
	restaurantConfig,
	welcomeTokensConfig,
	orderTicketExportConfig,
	orderStatusTransitionsConfig,
	paymentStatusTransitionsConfig,
	cartsConfig,
	cartItemsConfig,
	paymentMethodConfig,
	checkinGroupConfig,
	productConfig
];

export const resourceIdPropNameMap = resourcesToIdPropNameMap(resources);

export default resources;
