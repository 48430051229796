import {
	Button,
	Card,
	CardHeader,
	Divider,
	Grid,
	TextField as MaterialTextField,
	Typography
} from "@material-ui/core";
import React, { useState } from "react";
import {
	BooleanField,
	NumberField,
	Tab,
	TabbedShowLayout,
	TextField,
	useMutation,
	useNotify,
	useRecordContext
} from "react-admin";

const TestSwishComponent = props => {
	const record = useRecordContext(props);
	const merchantSwishNumber = record.swishId;
	const notify = useNotify();
	const [phoneNumber, setPhoneNumber] = useState(null);

	const [initatePayment, { loading }] = useMutation(
		{
			type: "getOne",
			resource: "test-swish",
			payload: {
				id: `${phoneNumber}/${merchantSwishNumber}`
			}
		},
		{
			onSuccess: ({ data }) => {
				const errors = data.data.errors;
				const status = data.data.status;

				if (status === 201) {
					notify("Swish tested sucessfully! Check your phone!");
				} else if (status === 422) {
					for (let error of errors) {
						if (error.errorCode === "PA01")
							notify(
								`Merchant is not enrolled to Passbuy (PA01)`,
								"warning"
							);
						else
							notify(
								`${error.errorCode}: ${error.errorMessage}`,
								"warning"
							);
					}
				} else {
					notify(`Swish down. ${JSON.stringify(data)}`, "warning");
				}
			},
			onFailure: error => notify(`Error ${error.message}`, "warning")
		}
	);
	const onInitiatePaymentButtonClick = () => {
		if (!merchantSwishNumber)
			notify("Missing merchant swish number", "warning");
		if (!/^467\d{8}$/.test(phoneNumber)) {
			notify(`Phone number must have format 467XXXXXXXX`, "warning");

			return;
		}

		initatePayment();
	};

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<MaterialTextField
						id="standard-disabled"
						label="Your Swish Number"
						defaultValue={null}
						onChange={e => setPhoneNumber(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						color="primary"
						onClick={onInitiatePaymentButtonClick}
					>
						Initiate Payment
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				&nbsp;
			</Grid>
		</>
	);
};

const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<CardHeader title={record?.name}></CardHeader>
			<TabbedShowLayout>
				<Tab label="Details">
					<TextField source="id" sortable={false} emptyText="-" />
					<BooleanField
						label="External Product Sync"
						source="externalProductSync"
						emptyText="-"
					/>
				</Tab>
				<Tab label="Payment Methods">
					<div>
						<Typography variant="h6">Swish</Typography>
					</div>
					<BooleanField
						label="Swish Enabled"
						source="providers.swish"
						emptyText="-"
					/>
					<TextField
						label="Swish number"
						source="swishId"
						emptyText="-"
					/>
					<BooleanField
						label="Has Own Swish Certificate"
						source="hasOwnSwishCertificate"
						emptyText="-"
					/>
					<BooleanField
						label="Swedbank Pay Enabled"
						source="providers.swedbank_pay"
						emptyText="-"
					/>
					<TextField
						label="Swedbank Pay Account ID"
						source="swedbankPayId"
						emptyText="-"
					/>
					<div>
						<Typography variant="h6">Card/Apple Pay</Typography>
					</div>
					<BooleanField
						label="Stripe Enabled"
						source="providers.stripe"
						emptyText="-"
					/>
					<TextField
						label="Stripe account ID"
						source="stripeId"
						emptyText="-"
					/>
				</Tab>

				<Tab label="Fees" path="fee">
					<NumberField
						label="Swish Fixed Fee"
						source="fee.swishFixed"
						locales="sv-SE"
						options={{ style: "currency", currency: "SEK" }}
						emptyText="-"
					/>
					<NumberField
						label="Swish Variable Fee"
						source="fee.swishVariable"
						options={{ maximumFractionDigits: 2, style: "percent" }}
						emptyText="-"
					/>
					<Divider></Divider>
					<NumberField
						label="Stripe Fixed Fee (EU)"
						source="fee.stripeEuFixed"
						locales="sv-SE"
						options={{ style: "currency", currency: "SEK" }}
						emptyText="-"
					/>
					<NumberField
						label="Stripe Variable Fee (EU)"
						source="fee.stripeEuVariable"
						options={{ maximumFractionDigits: 2, style: "percent" }}
						emptyText="-"
					/>

					<NumberField
						label="Stripe Fixed Fee (NON-EU)"
						source="fee.stripeNonEuFixed"
						locales="sv-SE"
						options={{ style: "currency", currency: "SEK" }}
						emptyText="-"
					/>
					<NumberField
						label="Stripe Variable Fee (NON-EU)"
						source="fee.stripeNonEuVariable"
						options={{ maximumFractionDigits: 2, style: "percent" }}
						emptyText="-"
					/>
					<Divider></Divider>
					<NumberField
						label="Swedbank Pay Swish Fixed Fee"
						source="fee.swedbankPaySwishFixed"
						locales="sv-SE"
						options={{ style: "currency", currency: "SEK" }}
						emptyText="-"
					/>
					<NumberField
						label="Swedbank Pay Swish Variable Fee"
						source="fee.swedbankPaySwishVariable"
						options={{ maximumFractionDigits: 2, style: "percent" }}
						emptyText="-"
					/>
				</Tab>
				<Tab label="Test Swish Number" path="test-swish">
					<TextField
						label="Merchant Swish number"
						source="swishId"
						emptyText="-"
					/>
					<TestSwishComponent {...props} />
				</Tab>
			</TabbedShowLayout>
		</Card>
	);
};

export default Component;
