import { Button, Card, CardHeader, useMediaQuery } from "@material-ui/core";
import { Check, Clear } from "@material-ui/icons";
import React from "react";
import {
	BooleanField,
	Datagrid,
	Link,
	Pagination,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";

const AlertBooleanField = props => {
	const { source } = props;
	const record = useRecordContext(props);
	const alert = record[source];

	if (alert)
		return (
			<>
				<span style={{ color: "green" }}>
					<Check />
				</span>
			</>
		);
	else
		return (
			<span style={{ color: "red" }}>
				<Clear />
			</span>
		);
};

const AlertField = props => {
	const { source } = props;
	const record = useRecordContext(props);
	const alert = record["alert"];
	const alertDelay = record["alertDelay"];
	const sound = record["sound"];

	if (alert)
		return (
			<>
				<span style={{ color: "green" }}>
					{alertDelay && `${alertDelay} min`}
					{sound && ` with sound`}
				</span>
			</>
		);
	else return <></>;

	return record[source];
};

const Component = ({ children, props }) => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader
				action={
					<>
						<Button
							component={Link}
							color="primary"
							to={{
								pathname: `/stations/create?filter=${encodeURIComponent(
									JSON.stringify({ storeId: record.id })
								)}`
							}}
						>
							Create
						</Button>
					</>
				}
				title="Stations"
			></CardHeader>

			<ReferenceManyField
				label="Stations"
				reference="stations"
				target="storeId"
				sort={{ field: "name", order: "ASC" }}
				perPage={10}
				pagination={<Pagination />}
			>
				<Datagrid {...props} rowClick="show">
					<TextField source="name" emptyText="-" sortable={true} />
					<BooleanField source="isPickup" label="Pickup" />
					<AlertBooleanField source="alert" label="Alert" />
					<AlertField label="" />
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
