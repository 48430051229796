import { Card, CardHeader, Grid } from "@material-ui/core";
import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField,
	FunctionField,
	ReferenceManyField,
	Datagrid
} from "ra-ui-materialui";
import { TabbedShowLayout, Tab } from "react-admin";

import { Title } from "./components";

const Container = props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);

export default props => (
	<Show {...props} component={Container} title={<Title />}>
		<>
			<Grid item xs={12} md={7} lg={6} xl={6}>
				<Card>
					<TabbedShowLayout>
						<Tab label="Details">
							<ReferenceField
								source="merchantId"
								reference="merchants"
								link="show"
							>
								<TextField source="name" emptyText="-" />
							</ReferenceField>
							<TextField
								label="Integration ID"
								source="id"
								sortable={false}
								emptyText="-"
							/>
							<TextField
								source="provider"
								sortable={false}
								emptyText="-"
							/>
						</Tab>
						<Tab label="Config">
							<FunctionField
								addLabel={false}
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.config,
											null,
											"  "
										)}
									</pre>
								)}
							/>
						</Tab>
						<Tab label="Credentials">
							<FunctionField
								addLabel={false}
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.credentials,
											null,
											"  "
										)}
									</pre>
								)}
							/>
						</Tab>
					</TabbedShowLayout>
				</Card>
			</Grid>
			<Grid item xs={12} md={5} lg={6} xl={6}>
				<Card>
					<CardHeader title="Functions"></CardHeader>
					<ReferenceManyField
						label="Integrations"
						reference="integration-functions"
						target="integrationId"
						sort={false}
					>
						<Datagrid {...props} rowClick="show">
							<TextField source="provider" emptyText="-" />
						</Datagrid>
					</ReferenceManyField>
				</Card>
			</Grid>
		</>
	</Show>
);
