import { Grid } from "@material-ui/core";
import { Show } from "react-admin";
import { Checkins, Container, Deals, Stations, StoreInfo } from "./components";

const Title = ({ record: { name } }) => {
	return <span>{`Store / ${name}`}</span>;
};

export default props => (
	<Show {...props} component={Container} title={<Title />}>
		<>
			<Grid item xs={12} md={12} lg={12} xl={4}>
				<StoreInfo />
			</Grid>

			<Grid item xs={12} md={12} lg={12} xl={7}>
				<Checkins />
				<br />
				<Stations />
				<br />
				<Deals />
			</Grid>
		</>
	</Show>
);
