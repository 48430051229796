import { Button, Card, CardHeader, useMediaQuery } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import React from "react";
import {
	Datagrid,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";
import { Image } from "./components";

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader
				action={
					<Button
						component={Link}
						color="primary"
						to={{
							pathname: "/stores/create",
							state: { record: { merchantId: record.id } },
							search: `?source=${JSON.stringify({
								merchantId: record.id
							})}`
						}}
					>
						Create
					</Button>
				}
				title="Stores"
			></CardHeader>

			<ReferenceManyField
				label="Stores"
				reference="stores"
				target="merchantId"
				sort={{ field: "name", order: "ASC" }}
			>
				<Datagrid
					{...props}
					rowClick="show"
					empty={<EmptyCard text="No stores exists yet" />}
				>
					<Image />
					<TextField source="name" emptyText="-" />
					{!isXSmall && <TextField source="orgNo" emptyText="-" />}
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
