import React, { useCallback } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { Header, CreateForm, EditForm } from "./components";
import { Create, Edit, useMutation } from "react-admin";

const CreateComponent = props => {
	return (
		<Card>
			<Header title="Create"></Header>
			<Create
				actions={<></>}
				{...props}
				title={<>Create</>}
				component={Grid}
			>
				<CreateForm></CreateForm>
			</Create>
		</Card>
	);
};

const EditComponent = props => {
	return (
		<Card>
			<Header title="Edit"></Header>
			<Edit actions={<></>} {...props} title="Edit" component={Grid}>
				<EditForm></EditForm>
			</Edit>
		</Card>
	);
};
export { CreateComponent, EditComponent };
