import EuroIcon from "@material-ui/icons/Euro";
import List from "./List";
import Show from "./Show";

export default {
	name: "price-lists",
	idPropName: "pricelistId",
	icon: EuroIcon,
	list: List,
	show: Show
};
