import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import {
	endOfYesterday,
	startOfMonth,
	startOfWeek,
	subMonths,
	subWeeks
} from "date-fns";
import { useMemo } from "react";
import {
	AutocompleteArrayInput,
	FilterList,
	FilterListItem,
	ReferenceArrayInput,
	useListFilterContext
} from "react-admin";
import { Form } from "react-final-form";

const Card = withStyles(theme => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			order: -1, // display on the left rather than on the right of the list
			width: "20em",
			marginRight: "1em"
		},
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	}
}))(MuiCard);

const FilterMerchants = props => {
	const { source, ...rest } = props;
	const { filterValues, setFilters } = useListFilterContext();

	const initialValues = useMemo(
		() => ({
			[source]: filterValues[source]
		}),
		[filterValues, source]
	);

	const onSubmit = () => undefined;

	const onChange = merchantIds => {
		console.log("object", merchantIds, {
			...filterValues,
			[source]: merchantIds
		});
		setFilters({ ...filterValues, [source]: merchantIds }, null);
	};
	return (
		<Form initialValues={initialValues} onSubmit={onSubmit}>
			{() => (
				<ReferenceArrayInput
					onChange={onChange}
					setFilter={() => ({})}
					sort={false}
					shouldRenderSuggestions={true}
					perPage={10000}
					source={source}
					label={""}
					reference="merchants"
				>
					<AutocompleteArrayInput source="id" />
				</ReferenceArrayInput>
			)}
		</Form>
	);
};

export default () => (
	<Card>
		<CardContent>
			<FilterList label="Created">
				<FilterListItem
					label="Today"
					value={{
						time_created_gte: endOfYesterday().toISOString(),
						time_created_lte: undefined
					}}
				/>
				<FilterListItem
					label="This week"
					value={{
						time_created_gte: startOfWeek(new Date()).toISOString(),
						time_created_lte: undefined
					}}
				/>
				<FilterListItem
					label="Last week"
					value={{
						time_created_gte: subWeeks(
							startOfWeek(new Date()),
							1
						).toISOString(),
						time_created_lte: startOfWeek(new Date()).toISOString()
					}}
				/>
				<FilterListItem
					label="This month"
					value={{
						time_created_gte: startOfMonth(
							new Date()
						).toISOString(),
						time_created_lte: undefined
					}}
				/>
				<FilterListItem
					label="Last month"
					value={{
						time_created_gte: subMonths(
							startOfMonth(new Date()),
							1
						).toISOString(),
						time_created_lte: startOfMonth(new Date()).toISOString()
					}}
				/>
			</FilterList>
			<FilterList label="Type">
				<FilterListItem label="Purchase" value={{ type: "PURCHASE" }} />
				<FilterListItem label="Refund" value={{ type: "REFUND" }} />
			</FilterList>
			<FilterList label="Status">
				<FilterListItem label="Pending" value={{ status: "PENDING" }} />
				<FilterListItem
					label="Completed"
					value={{ status: "COMPLETED" }}
				/>
				<FilterListItem
					label="Refunded"
					value={{ status: "REFUNDED" }}
				/>
				<FilterListItem label="Failed" value={{ status: "FAILED" }} />
			</FilterList>
			<FilterList label="Merchants">
				<FilterMerchants source="merchant_id_in" />
			</FilterList>
		</CardContent>
	</Card>
);
