import Group from "@material-ui/icons/Group";

export default {
	name: "fortnox-report-details",
	options: {
		label: "Fortnox report details"
	},
	idPropName: "orderId",
	icon: Group
};
