import {
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import { curry, values as objectValues } from "ramda";
import React from "react";
import {
	AutocompleteArrayInput,
	Create,
	ReferenceArrayInput,
	regex,
	SimpleForm,
	Tab,
	TabbedShowLayout,
	TextInput,
	useGetList
} from "react-admin";
import { useField, useFormState } from "react-final-form";

const validatePhoneNumber = regex(
	/^\+46[0-9]{9}$/,
	"Must be a Swedish mobile number +467XXXXXXXX"
);

const MerchantAccess = ({ name, source, label, record }) => {
	const { values } = useFormState();
	const {
		input: { onChange }
	} = useField(name);

	const { data: dashboardUserFunctions } = useGetList(
		"dashboard-user-functions"
	);

	const merchants = values.merchantIds || [];
	const { data: merchantMap } = useGetList(
		"merchants",
		{ page: 1, perPage: 100 },
		{
			merchantId: "desc"
		},
		{
			...(values.merchantIds && { merchantId: values.merchantIds })
		}
	);

	const accessState = values[source] || {};
	const onAccessFunctionChange = curry((merchantId, event) => {
		onChange({
			...accessState,
			[merchantId]: {
				...accessState[merchantId],
				[event.target.name]: event.target.checked
			}
		});
	});

	return (
		<>
			<TabbedShowLayout>
				{merchants.map(m => (
					<Tab label={merchantMap[m]?.name || "Unknown"}>
						<Table
							size="small"
							sx={{ minWidth: 650 }}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
									<TableCell width="350">Function</TableCell>
									<TableCell align="left">Enabled</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{dashboardUserFunctions &&
									objectValues(dashboardUserFunctions)?.map(
										af => (
											<TableRow>
												<TableCell>
													{af.label}
												</TableCell>
												<TableCell align="left">
													<Switch
														color="primary"
														name={af.id}
														defaultChecked={
															af.default
														}
														checked={
															accessState &&
															accessState[m] &&
															accessState[m][
																af.id
															]
														}
														onChange={onAccessFunctionChange(
															m
														)}
													/>
												</TableCell>
											</TableRow>
										)
									)}
							</TableBody>
						</Table>
					</Tab>
				))}
			</TabbedShowLayout>
		</>
	);
};

const Form = props => {
	return (
		<SimpleForm {...props} redirect="show">
			<TextInput
				style={{ marginTop: 0 }}
				source="phonenumber"
				label="Phone Number"
				margin="normal"
				validate={validatePhoneNumber}
			/>
			<ReferenceArrayInput
				setFilter={() => ({})}
				sort={false}
				shouldRenderSuggestions={true}
				perPage={10000}
				source="merchantIds"
				label="Merchants access"
				reference="merchants"
			>
				<AutocompleteArrayInput source="id" />
			</ReferenceArrayInput>
			<MerchantAccess
				name="merchantAccess"
				source="merchantAccess"
			></MerchantAccess>
		</SimpleForm>
	);
};

export default Form;
