import queryString from "query-string";
import React from "react";
import {
	BooleanInput,
	NumberInput,
	SimpleForm,
	TextInput,
	Toolbar
} from "react-admin";

const Component = props => {
	const queryParams = queryString.parse(props.location.search);
	const filter =
		queryParams.filter &&
		JSON.parse(decodeURIComponent(queryParams.filter));

	return (
		<SimpleForm
			toolbar={<Toolbar alwaysEnableSaveButton />}
			{...props}
			redirect="show"
			initialValues={{ ...filter, alertDelay: 10 }}
		>
			<TextInput
				style={{ marginTop: 0 }}
				source="name"
				helperText="E.g: Kitchen"
				margin="normal"
				allowEmpty={true}
				emptyValue=""
			/>
			<BooleanInput
				source="isPickup"
				label="Pickup station"
				helperText="The Pickup station will get an order ticket that will put the delivery to ready for pickup."
			></BooleanInput>
			<BooleanInput source="alert"></BooleanInput>
			<NumberInput
				source="alertDelay"
				min="1"
				max="100"
				helperText="The amount of minutes until the alert start"
			/>
			<BooleanInput source="sound"></BooleanInput>
		</SimpleForm>
	);
};

export default Component;
