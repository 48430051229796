import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import { createElement } from "react";
import { getResources, MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const Menu = ({ onMenuClick, logout, ...menu }) => {
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	const open = useSelector(state => state.admin.ui.sidebarOpen);
	const resources = useSelector(getResources);
	const menuResources = [
		"merchants",
		"orders",
		"dashboard-users",
		"payment-providers",
		"companies",
		"restaurants"
	];
	const ucfirst = name => name.charAt(0).toUpperCase() + name.slice(1);

	return (
		resources.length > 0 && (
			<div>
				{menuResources.map(mr => {
					const resource = resources.find(f => f?.name === mr);
					return (
						<MenuItemLink
							key={resource.name}
							to={`/${resource.name}`}
							primaryText={
								resource.options.label || ucfirst(resource.name)
							}
							leftIcon={createElement(resource.icon)}
							onClick={onMenuClick}
							sidebarIsOpen={open}
						/>
					);
				})}
				{isXSmall && logout}
			</div>
		)
	);
};

export default withRouter(Menu);
