import { useMediaQuery } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import React, { Fragment } from "react";
import {
	BulkDeleteButton,
	BulkExportButton,
	Datagrid,
	downloadCSV,
	ExportButton,
	List,
	sanitizeListRestProps,
	SimpleList,
	TextField,
	TopToolbar,
	useListContext
} from "react-admin";
import { generateQrUrl } from "utils";
import BulkMoveButton from "./BulkMoveButton";
import QrTextField from "./Show/QrTextField";

const DesktopGrid = props => (
	<Datagrid {...props} isRowSelectable={() => true} rowClick="show">
		<TextField source="label" emptyText="-" sortable={true} />
		<TextField source="externalTableId" sortable={true} emptyText="-" />
		<TextField label="Id" source="id" sortable={true} emptyText="-" />
		<QrTextField label="QR" source="id"></QrTextField>
	</Datagrid>
);

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => record.label}
		linkType="show"
	/>
);

const exporter = posts => {
	const postsForExport = posts.map(post => {
		const { label, id } = post;

		return { Label: label, QR: generateQrUrl(id) };
	});
	jsonExport(
		postsForExport,
		{
			headers: ["Label", "QR"]
		},
		(err, csv) => {
			downloadCSV(csv, "checkins");
		}
	);
};

const PostBulkActionButtons = props => (
	<Fragment>
		<BulkDeleteButton {...props} />
		<BulkExportButton {...props} />
		<BulkMoveButton {...props} />
	</Fragment>
);

const ListActions = props => {
	const { className, exporter, filters, maxResults, ...rest } = props;
	const { currentSort, resource, filterValues, total } = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filterValues={filterValues}
				maxResults={maxResults}
			/>
		</TopToolbar>
	);
};

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<List
			hasCreate={false}
			{...props}
			actions={<ListActions />}
			bulkActionButtons={<PostBulkActionButtons />}
			perPage={0}
			pagination={false}
			exporter={exporter}
			title="Export checkins"
			sort={{ field: "label", order: "ASC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
