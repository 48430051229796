import List from "./List";
import Show from "./Show";

export default {
	name: "checkingroups",
	idPropName: "checkin_group_id",
	show: Show,
	//create: CreateComponent,
	//edit: EditComponent,
	list: List
};
