import React, { useState } from "react";
import {
	SimpleForm,
	TextInput,
	NumberInput,
	required,
	BooleanInput,
	useMutation,
	useNotify
} from "react-admin";
import { anyPass, compose, defaultTo, gte, lte, startsWith } from "ramda";
import { floor, isNumber, validateInput, whenFloatString } from "utils";
import { PercentageField } from "modules/core/components";
import { Button, Input, TextField, Typography } from "@material-ui/core";

const Form = props => {
	return (
		<SimpleForm {...props} redirect="show">
			<TextInput
				style={{ marginTop: 0 }}
				source="name"
				helperText="Ex: Los Pollos Hermanos"
				margin="normal"
				validate={required("Name is required")}
			/>
			<TextInput
				label="Stripe account id"
				source="stripeId"
				helperText="Ex: acct_xxxxxxxxxxxxxxxx"
				margin="normal"
				validate={[
					validateInput(
						compose(startsWith("acct_"), defaultTo("")),
						"Must start with 'acct_'"
					)
				]}
			/>

			<TextInput
				label="Swish number"
				source="swishId"
				helperText="Ex: 1235555555"
				margin="normal"
				validate={[
					validateInput(
						anyPass([
							compose(startsWith("123"), defaultTo("")),
							compose(startsWith("987"), defaultTo(""))
						]),
						"Must start with '123' or '987'"
					),
					validateInput(
						s => s.length === 10,
						"Must be 10 digits long"
					)
				]}
			/>

			<BooleanInput source="externalProductSync" />
			<Typography variant="h6">Swish</Typography>
			<BooleanInput source="providers.swish" label="Swish enabled" />
			<NumberInput
				label="Swish Fixed Fee"
				source="fee.swishFixed"
				helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
				initialValue={0}
				parse={whenFloatString(compose(floor, parseFloat))}
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0 or greater"),
					required("Fixed transaction fee is required")
				]}
			/>
			<PercentageField
				label="Swish Variable Fee"
				source="fee.swishVariable"
				helperText="Amount in % of the transaction amount (0-5)"
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0% or greater"),
					validateInput(gte(0.05), "Must be 5% or less"),
					required("Variable transaction fee is required")
				]}
			/>
			<Typography variant="h6">Stripe</Typography>
			<BooleanInput source="providers.stripe" label="Stripe enabled" />
			<Typography variant="h7">Stripe EU Fees</Typography>
			<NumberInput
				label="Stripe EU Fixed Fee"
				source="fee.stripeEuFixed"
				helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
				initialValue={0}
				parse={whenFloatString(compose(floor, parseFloat))}
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0 or greater"),
					required("Fixed transaction fee is required")
				]}
			/>
			<PercentageField
				label="Stripe EU Variable Fee"
				source="fee.stripeEuVariable"
				helperText="Amount in % of the transaction amount (0-5)"
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0% or greater"),
					validateInput(gte(0.05), "Must be 5% or less"),
					required("Variable transaction fee is required")
				]}
			/>
			<Typography variant="h7">Stripe NON-EU Fees</Typography>
			<NumberInput
				label="Stripe NON-EU Fixed Fee"
				source="fee.stripeNonEuFixed"
				helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
				initialValue={0}
				parse={whenFloatString(compose(floor, parseFloat))}
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0 or greater"),
					required("Fixed transaction fee is required")
				]}
			/>
			<PercentageField
				label="Stripe NON-EU Variable Fee"
				source="fee.stripeNonEuVariable"
				helperText="Amount in % of the transaction amount (0-5)"
				margin="normal"
				validate={[
					validateInput(isNumber, "Must be a number"),
					validateInput(lte(0), "Must be 0% or greater"),
					validateInput(gte(0.05), "Must be 5% or less"),
					required("Variable transaction fee is required")
				]}
			/>
		</SimpleForm>
	);
};

export default Form;
