import { Button, Card, CardHeader, Grid } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import env from "env";
import rowStyle from "modules/orders/RowStyle";
import moment from "moment-timezone";
import QRCode from "qrcode.react";
import {
	BooleanField,
	DateField,
	FunctionField,
	Link,
	ReferenceField,
	ReferenceManyField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";
import { generateQrUrl } from "utils";
import generateWebAppQR from "utils/generate-web-app-qr-url";
import Container from "./components/Container";
import QrTextField from "./QrTextField";

const Component = props => {
	const record = useRecordContext(props);

	return (
		<>
			<Grid item xs={12} md={12} lg={6} xl={6}>
				<Card>
					<CardHeader
						action={
							["local", "development"].includes(env.name) ? (
								<Button
									component={Link}
									color="primary"
									to={{
										pathname: "/carts/create",
										search: `?source=${JSON.stringify({
											checkinId: record.id
										})}`
									}}
								>
									Create Cart (development)
								</Button>
							) : null
						}
					></CardHeader>
					<TabbedShowLayout>
						<Tab label="Details">
							<TextField source="id" emptyText="-" />
							<ReferenceField
								link="show"
								source="checkin_group_id"
								reference="checkingroups"
							>
								<TextField source="name" />
							</ReferenceField>
							<TextField source="label" emptyText="-" />
							<TextField
								source="external_table_id"
								emptyText="-"
							/>
							<BooleanField source="active" />
							<FunctionField
								label="QR code"
								render={record => (
									<QRCode
										size="100"
										value={generateQrUrl(record?.id)}
									/>
								)}
							/>
							<QrTextField
								formatFunction={generateQrUrl}
								label="QR Url"
								source="id"
							></QrTextField>
						</Tab>
						<Tab label="Tab QR">
							<FunctionField
								label="Tab QR Code"
								render={record => (
									<QRCode
										size="100"
										value={generateWebAppQR(
											"tab",
											record?.id
										)}
									/>
								)}
							/>

							<QrTextField
								formatFunction={generateWebAppQR("tab")}
								label="TAB Url"
								source="id"
							></QrTextField>
						</Tab>
						<Tab label="WEB APP QR">
							<FunctionField
								label="WEB APP QR Code"
								render={record => (
									<QRCode
										size="100"
										value={generateWebAppQR(
											"order_and_pay",
											record?.id
										)}
									/>
								)}
							/>

							<QrTextField
								formatFunction={generateWebAppQR(
									"order_and_pay"
								)}
								label="TAB Url"
								source="id"
							></QrTextField>
						</Tab>
					</TabbedShowLayout>
				</Card>
			</Grid>
			<Grid item xs={12} md={12} lg={6} xl={6}>
				<ReferenceManyField
					label="Orders"
					reference="orders"
					target="checkin_id"
					sort={{ field: "time_created", order: "DESC" }}
				>
					<Card>
						<CardHeader title="Orders"></CardHeader>
						<ResponsiveDataGrid
							{...props}
							rowStyle={rowStyle(null)}
							empty={
								<EmptyCard text="No orders exists yet for this check in" />
							}
							rowClick={id => `/orders/${id}/show`}
							primaryText={record => record.store_name}
							secondaryText={record =>
								`${moment(record.time_created)
									.tz("Europe/Stockholm")
									.format("YYYY-MM-DD HH:mm:ss")} - ${
									record.id
								}`
							}
							tertiaryText={record => `${record.total}`}
						>
							<TextField source="id" emptyText="-" />
							<DateField showTime={true} source="time_created" />
							<TextField label="Total" source="total" />
						</ResponsiveDataGrid>
					</Card>
				</ReferenceManyField>
			</Grid>
		</>
	);
};
export default props => {
	return (
		<Show {...props} component={Container}>
			<Component {...props} />
		</Show>
	);
};
