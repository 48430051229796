import React from "react";
import { Card, CardHeader } from "@material-ui/core";
import { Header, Form } from "./components";
import { Create, Edit } from "ra-ui-materialui";
import { redirectionSaga, useNotify, useRedirect, useRefresh } from "ra-core";

const CreateComponent = props => {
	return (
		<Card>
			<CardHeader title="Create"></CardHeader>
			<Create mutationMode="pessimistic" actions={false} {...props}>
				<Form {...props} />
			</Create>
		</Card>
	);
};

const EditComponent = props => {
	return (
		<Card>
			<CardHeader title="Edit"></CardHeader>
			<Edit mutationMode="pessimistic" actions={false} {...props}>
				<Form {...props} />
			</Edit>
		</Card>
	);
};

export { CreateComponent, EditComponent };
