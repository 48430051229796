const { useMediaQuery } = require("@material-ui/core");
import { Card, CardHeader } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { useTheme } from "@material-ui/core/styles";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import moment from "moment-timezone";
import React from "react";
import {
	DateField,
	NumberField,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";

const rowStyle = selectedRow => record => {
	const theme = useTheme();
	let style = {};
	if (!record) {
		return style;
	}
	if (selectedRow && selectedRow === record.id) {
		style = {
			...style,
			backgroundColor: theme.palette.action.selected
		};
	}
	if (record.status === "completed")
		return {
			...style,
			borderLeftColor: green[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (record.status === "started")
		return {
			...style,
			borderLeftColor: orange[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (record.status === "error")
		return {
			...style,
			borderLeftColor: red[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	return style;
};

const Elapsed = props => {
	const { source } = props;
	const orderTicket = useRecordContext(props);

	const elapsed = moment
		.duration(
			moment(orderTicket.timeCompleted).diff(
				moment(orderTicket.timeStarted)
			)
		)
		.asSeconds();

	return `${elapsed}s`;
};

const Component = ({ selectedRow, ...props }) => {
	return (
		<Card>
			<CardHeader title="Order Exports"></CardHeader>
			<ReferenceManyField
				label="Order Exports"
				reference="orderexports"
				target="orderId"
				source="id"
				empty={
					<EmptyCard text="No order exports exists for this order" />
				}
				sort={false}
			>
				<ResponsiveDataGrid
					rowClick="show"
					primaryText={record => `${record.provider}`}
					tertiaryText={record => `${record.status}`}
					secondaryText={record =>
						`${moment(record.timeStarted).format(
							"YYYY-MM-DD HH:mm:ss"
						)} - ${moment(record.timeCompleted).format(
							"YYYY-MM-DD HH:mm:ss"
						)}`
					}
					rowStyle={rowStyle(selectedRow)}
				>
					<TextField label="Provider" source="provider" />
					<DateField
						label="Started"
						source="timeStarted"
						showTime={true}
					/>
					<DateField
						label="Completed"
						source="timeCompleted"
						showTime={true}
					/>
					<Elapsed label="Elapsed" />
					<TextField label="Status" source="status" />
					<NumberField label="Retries" source="retries" />
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
