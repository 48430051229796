import { Card, CardHeader, useMediaQuery } from "@material-ui/core";
import { toHumanString } from "human-readable-numbers";
import { path } from "ramda";
import React from "react";
import {
	ArrayField,
	Datagrid,
	DateField,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";

const UrlField = ({ record, source }) => <a href={record[source]}>Allabolag</a>;

const RevenueField = props => {
	const { source } = props;
	const record = useRecordContext(props);

	if (!record[source]) return "N/A";
	const value = path([source], record) * 1000;

	return `${toHumanString(value)}`;
};

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader title="Possible company connections"></CardHeader>

			<ReferenceManyField
				label="Companies"
				reference="companies"
				target="undefined"
				filter={{ orgnr_ia: record.possible_orgnrs }}
			>
				{isXSmall ? (
					<MobileGrid />
				) : (
					<Datagrid {...props} rowClick="show">
						<TextField source="orgnr" emptyText="" />
						<TextField source="name" emptyText="" />
						<TextField source="type" emptyText="" />
						<TextField source="main_group" emptyText="" />
						<TextField source="sub_group" emptyText="" />
						<UrlField source="link" emptyText="" />
						<TextField source="remarks" emptyText="" />
						<TextField source="year_founded" emptyText="" />
						<TextField source="phone_number" emptyText="" />
						<RevenueField source="revenue" emptyText="" />
						<TextField source="county" emptyText="" />
					</Datagrid>
				)}
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
