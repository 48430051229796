import { Button, Card, CardHeader, useMediaQuery } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import { stringify } from "query-string";
import React from "react";
import {
	ArrayField,
	ChipField,
	Datagrid,
	ReferenceManyField,
	SingleFieldList,
	TextField,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader
				action={
					<Button
						component={Link}
						color="primary"
						to={{
							pathname: "/integrations/create",
							state: { record: { merchantId: record.id } },
							search: `?source=${JSON.stringify({
								merchantId: record.id
							})}`
						}}
					>
						Create
					</Button>
				}
				title="Integrations"
			></CardHeader>

			<ReferenceManyField
				label="Integrations"
				reference="integrations"
				target="merchantId"
				sort={false}
			>
				<Datagrid
					{...props}
					empty={<EmptyCard text="No integrations exists yet" />}
					rowClick="show"
				>
					<TextField source="provider" emptyText="-" />
					<ArrayField source="functions">
						<SingleFieldList linkType={false}>
							<ChipField source="type" />
						</SingleFieldList>
					</ArrayField>
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
