import {
	FormControlLabel,
	FormGroup,
	Switch,
	Typography
} from "@material-ui/core";
import React from "react";
import { useInput, useRecordContext, useField } from "react-admin";
import { useFormState } from "react-final-form";

const FunctionsInput = ({ source, schema, stores, ...props }) => {
	const { values } = useFormState();
	const record = useRecordContext(props);
	const {
		input: { onChange }
	} = useInput(props);
	const value = values && values[source] ? values[source] : [];

	if (!values.merchantId) return <span>Missing merchant</span>;
	if (!schema[values.provider]) return null;

	const functionLabels = {
		get_tab: "Tab",
		order_export: "Order Export",
		product_import: "Product Import",
		table_import: "Table Import"
	};

	if (!values.provider) return null;

	const merge = ({ storeId, functionName, checked }) => {
		const removed = value.filter(f => {
			if (
				f.type === functionName &&
				(f.storeId === storeId || !storeId) &&
				!checked
			)
				return false;

			return true;
		});

		if (!checked) return removed;

		const found = value.find(
			f =>
				f.type === functionName &&
				(f.storeId === storeId || !storeId) &&
				checked
		);

		if (found) return removed;

		return [
			...removed,
			{
				type: functionName,
				...(storeId && { storeId }),
				config: {}
			}
		];
	};

	const onFunctionChange = func => {
		const merged = merge(func);

		onChange(merged);
	};

	const functions = Object.keys(
		schema[values.provider].supportedFunctions
	).flatMap(f => {
		if (f === "product_import")
			return [
				...[{ divider: true, label: "Product Import" }],
				...Object.keys(stores).map(storeId => ({
					function: f,
					storeId,
					label: `${stores[storeId].name}`
				}))
			];

		if (f === "table_import")
			return [
				...[{ divider: true, label: "Table Import" }],
				...Object.keys(stores).map(storeId => ({
					function: f,
					storeId,
					label: `${stores[storeId].name}`
				}))
			];

		return [
			{
				function: f,
				label: functionLabels[f]
			}
		];
	});

	return (
		<>
			{functions.map(f =>
				f.divider ? (
					<Typography variant="h6">{f.label}</Typography>
				) : (
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									name={f.function}
									onChange={event => {
										onFunctionChange({
											storeId: f.storeId,
											functionName: event.target.name,
											checked: event.target.checked
										});
									}}
									checked={
										value.find(
											v =>
												v.type === f.function &&
												(v.storeId === f.storeId ||
													!f.storeId)
										) !== undefined
									}
									color="primary"
									defaultChecked
								/>
							}
							label={f.label}
						/>
					</FormGroup>
				)
			)}
		</>
	);
};

export default FunctionsInput;
