import { compose, defaultTo, test } from "ramda";
import React from "react";
import {
	ArrayInput,
	BooleanInput,
	ImageField,
	ImageInput,
	RadioButtonGroupInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	useRecordContext
} from "react-admin";
import { validateInput } from "utils";

const Component = props => {
	const record = useRecordContext(props);
	const merchantId = record?.merchantId;

	return (
		<SimpleForm
			{...props}
			redirect="show"
			initialValues={{ preferredApp: "NATIVE" }}
		>
			<TextInput
				style={{ marginTop: 0 }}
				source="name"
				helperText="Ex: Los Pollos Hermanos"
				margin="normal"
				validate={required("Name is required")}
			/>

			<TextInput
				style={{ marginTop: 0 }}
				multiline
				source="information"
				helperText="Information text"
				margin="normal"
			/>
			<RadioButtonGroupInput
				source="preferredApp"
				choices={[
					{ id: "WEB", name: "Web" },
					{ id: "NATIVE", name: "Native" }
				]}
			/>
			<ArrayInput disableReordering={true} source="availableApps">
				<SimpleFormIterator>
					<SelectInput
						source="type"
						defaultValue="ORDER_AND_PAY"
						label="App"
						choices={[
							{ id: "ORDER_AND_PAY", name: "ORDER_AND_PAY" },
							{ id: "TAB", name: "TAB" }
						]}
					/>
				</SimpleFormIterator>
			</ArrayInput>
			<ArrayInput
				disableReordering={true}
				source="availableDeliveryMethods"
			>
				<SimpleFormIterator>
					<SelectInput
						source="deliveryMethod"
						defaultValue="TABLE_SERVICE"
						label="Delivery Methodx"
						choices={[
							{ id: "TABLE_SERVICE", name: "TABLE_SERVICE" },
							{ id: "PICKUP", name: "PICKUP" }
						]}
					/>
				</SimpleFormIterator>
			</ArrayInput>
			<BooleanInput
				source="enableProductsBoughtTogether"
				label="Show products frequently bought together options"
				fullWidth={true}
			/>
			<ImageInput
				source="image.large"
				style={{ borderRadius: "50%" }}
				label="Large Image"
				helperText="Large image will be cropped to 180x180"
				accept="image/*"
				validate={required("Large image is required")}
				placeholder={<p>Drop image here</p>}
			>
				<ImageField source="image.large" title="Large Image" />
			</ImageInput>
			<ImageField source="image.large" label="Current Large Image" />

			<ImageInput
				source="image.small"
				label="Small Image"
				helperText="Small image will be cropped to 98x98"
				accept="image/*"
				validate={required("Small image is required")}
				placeholder={<p>Drop image here</p>}
			>
				<ImageField source="image.small" title="Small Image" />
			</ImageInput>
			<ImageField source="image.small" label="Current Small Image" />

			<ImageInput
				source="coverImage.large"
				helperText="Will be cropped to 1200x450"
				style={{ borderRadius: "50%" }}
				label="Large Cover Image"
				accept="image/*"
				placeholder={<p>Drop image here</p>}
			>
				<ImageField
					source="coverImage.large"
					title="Large Cover Image"
				/>
			</ImageInput>
			<ImageField
				source="coverImage.large"
				label="Current Large Cover Image"
			/>

			<ImageInput
				source="coverImage.small"
				helperText="Will be cropped to 640x360"
				style={{ borderRadius: "50%" }}
				label="Small Cover Image"
				accept="image/*"
				placeholder={<p>Drop image here</p>}
			>
				<ImageField
					source="coverImage.small"
					title="Small Cover Image"
				/>
			</ImageInput>
			<ImageField
				source="coverImage.small"
				label="Small Large Cover Image"
			/>

			<SelectInput
				source="type"
				allowEmpty={false}
				choices={[
					{ id: "RESTAURANT", name: "Restaurant" },
					{ id: "STORE", name: "Store" }
				]}
				validate={required("Store is required")}
			/>
			<ReferenceInput
				label="Price List"
				source="priceListId"
				reference="price-lists"
				filter={{ merchantId: merchantId }}
				sort={false}
				validate={required("Pricelist is required")}
			>
				<SelectInput optionText="name" />
			</ReferenceInput>

			<TextInput
				label="Address"
				source="address"
				helperText="Ex: Regeringsgatan 29"
				margin="normal"
				validate={required("Address is required")}
			/>
			<TextInput
				label="Organization Number"
				source="orgNo"
				helperText="Ex: 123456-7890"
				margin="normal"
				validate={[
					validateInput(
						compose(test(/^[0-9]{6}-[0-9]{4}$/), defaultTo("")),
						"Must be of format 123456-7890"
					),
					required("Organization Number is required")
				]}
			/>
		</SimpleForm>
	);
};

export default Component;
