import { Card, CardHeader, Grid } from "@material-ui/core";
import { Check, Error, PlayArrow } from "@material-ui/icons";
import ActionButton from "components/buttons/ActionButton";
import {
	Datagrid,
	FunctionField,
	ReferenceField,
	ReferenceManyField,
	Show,
	TextField
} from "ra-ui-materialui";
import {
	Pagination,
	Tab,
	TabbedShowLayout,
	useRecordContext
} from "react-admin";
import { Title } from "./components";

const Container = props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);

const IntegrationFunctions = props => {
	const record = useRecordContext(props);
	const merchantId = record.merchantId;
	return (
		<Card>
			<CardHeader title="Functions"></CardHeader>
			<ReferenceManyField
				label="Integrations"
				reference="integration-functions"
				target="integrationId"
				sort={false}
			>
				<Datagrid rowClick={false}>
					<TextField source="type" emptyText="-" />
					<TextField source="runHours" emptyText="-" />
					<ReferenceField
						link="show"
						source="storeId"
						reference="stores"
					>
						<TextField source="name" />
					</ReferenceField>
					<ActionButton
						resource="integration-functions"
						bodyFields={["storeId"]}
						visible={r => r.type === "product_import"}
						action="product_import"
						successMessage="Products have been synced!"
						actionId="id"
						actionBody={{ merchantId }}
						confirmTitle="Product import"
						confirmBody="Are you sure you want to sync products?"
					>
						Sync Products
					</ActionButton>
					<ActionButton
						resource="integration-functions"
						bodyFields={["storeId"]}
						visible={r => r.type === "table_import"}
						action="table_import"
						successMessage={data =>
							`Tables have been synced! (${data.newTableCount} created, ${data.updatedTableCount} updated, ${data.inactivatedMissingCount} inactivated)`
						}
						actionId="id"
						actionBody={{ merchantId }}
						confirmTitle="Table import"
						confirmBody="Are you sure you want to sync tables?"
					>
						Sync Tables
					</ActionButton>
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

const StatusField = props => {
	const { source } = props;
	const record = useRecordContext(props);
	const status = record[source];

	if (status === "completed")
		return (
			<span style={{ color: "green" }}>
				<Check />
			</span>
		);
	if (status === "error")
		return (
			<span style={{ color: "red" }}>
				<Error />
			</span>
		);
	if (status === "started")
		return (
			<span style={{ color: "orange" }}>
				<PlayArrow />
			</span>
		);

	return record[source];
};

export default props => {
	return (
		<Show {...props} component={Container} title={<Title />}>
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Card>
						<TabbedShowLayout>
							<Tab label="Details">
								<ReferenceField
									source="merchantId"
									reference="merchants"
									link="show"
								>
									<TextField source="name" emptyText="-" />
								</ReferenceField>
								<TextField
									label="Integration ID"
									source="id"
									sortable={false}
									emptyText="-"
								/>
								<TextField
									source="provider"
									sortable={false}
									emptyText="-"
								/>
							</Tab>
							<Tab label="Config">
								<FunctionField
									addLabel={false}
									render={record => (
										<pre
											style={{
												background: "#fafafa",
												padding: 15
											}}
										>
											{JSON.stringify(
												record.config,
												null,
												"  "
											)}
										</pre>
									)}
								/>
							</Tab>
							<Tab label="Credentials">
								<FunctionField
									addLabel={false}
									render={record => (
										<pre
											style={{
												background: "#fafafa",
												padding: 15
											}}
										>
											{JSON.stringify(
												record.credentials,
												null,
												"  "
											)}
										</pre>
									)}
								/>
							</Tab>
						</TabbedShowLayout>
					</Card>
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<IntegrationFunctions></IntegrationFunctions>
				</Grid>

				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Card>
						<CardHeader title="Logs"></CardHeader>
						<ReferenceManyField
							label="Integrations"
							reference="integration-logs"
							target="integrationId"
							sort={{ field: "timeStarted", order: "DESC" }}
							perPage={25}
							pagination={<Pagination />}
						>
							<Datagrid
								rowClick={(id, basePath, record) => {
									return `/integration-logs/${id}/show/?integrationId=${record.integrationId}`;
								}}
							>
								<TextField source="provider" emptyText="-" />
								<TextField source="type" emptyText="-" />
								<TextField source="timeStarted" emptyText="-" />
								<TextField source="timeStarted" emptyText="-" />
								<TextField
									source="timeCompleted"
									emptyText="-"
								/>
								<StatusField
									label=""
									source="status"
								></StatusField>
							</Datagrid>
						</ReferenceManyField>
					</Card>
				</Grid>
			</>
		</Show>
	);
};
