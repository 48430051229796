import { Grid } from "@material-ui/core";
import React from "react";
import Timeline from "./components/Timeline";
import { Show } from "react-admin";
import {
	Container,
	OrderInfo,
	OrderItems,
	OrderTickets,
	OrderExports,
	Payments
} from "./components";

const Title = ({ record: { id } }) => {
	return <span>{`Order / ${id}`}</span>;
};

export default props => {
	return (
		<Show
			{...props}
			actions={<></>}
			component={Container}
			title={<Title />}
		>
			<>
				<Grid item xs={12} md={12} lg={6} xl={6}>
					<OrderInfo />
				</Grid>
				<Grid item xs={12} md={12} lg={6} xl={6}>
					<Timeline {...props} />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<OrderItems />
				</Grid>

				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Payments />
				</Grid>

				<Grid item xs={12} md={12} lg={12} xl={12}>
					<OrderTickets />
				</Grid>

				<Grid item xs={12} md={12} lg={12} xl={12}>
					<OrderExports />
				</Grid>
			</>
		</Show>
	);
};
