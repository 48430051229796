import Group from "@material-ui/icons/Group";

export default {
	name: "stripe-payout-details",
	options: {
		label: "Stripe payout details"
	},
	idPropName: "orderId",
	icon: Group
};
