import { makeStyles } from "@material-ui/core";
import { ImageField } from "react-admin";

export default props => {
	const classes = useStyles();

	return (
		<ImageField
			{...{ props, classes }}
			source="image.small"
			label="Image"
			sortable={false}
		/>
	);
};

const useStyles = makeStyles({
	image: {
		height: 25,
		width: 25,
		display: "block",
		margin: 2
	}
});
