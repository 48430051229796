import EuroIcon from "@material-ui/icons/Euro";
import List from "./List";
import Show from "./Show";
import { CreateComponent, EditComponent } from "./CreateEdit";

export default {
	name: "integrations",
	idPropName: "integrationId",
	icon: EuroIcon,
	list: List,
	show: Show,
	edit: EditComponent,
	create: CreateComponent
};
