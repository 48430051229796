import { Card, CardHeader } from "@material-ui/core";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import rowStyle from "modules/orders/RowStyle";
import moment from "moment-timezone";
import React from "react";
import {
	DateField,
	Pagination,
	ReferenceManyField,
	TextField,
	useShowController
} from "react-admin";

const PaymentDetails = props => {
	const { record } = useShowController(props);

	if (!record) return null;

	return (
		<>
			<div>External ID: {record.external_id}</div>
			<div>
				Action: <pre>{JSON.stringify(record.action)}</pre>
			</div>
		</>
	);
};
const Component = ({ selectedRow, ...props }) => {
	return (
		<Card>
			<CardHeader title="Orders"></CardHeader>
			<ReferenceManyField
				label="Orders"
				reference="orders"
				target="user_id"
				pagination={<Pagination />}
				source="id"
				sort={{ field: "time_created", order: "DESC" }}
			>
				<ResponsiveDataGrid
					rowClick={"show"}
					rowStyle={rowStyle(selectedRow)}
					primaryText={record => record.store_name}
					secondaryText={record =>
						`${moment(record.time_created)
							.tz("Europe/Stockholm")
							.format("YYYY-MM-DD HH:mm:ss")} - ${record.id}`
					}
					tertiaryText={record => `${record.total}`}
				>
					<TextField source="id" emptyText="-" />
					<DateField showTime={true} source="time_created" />
					<TextField source="store_name" emptyText="-" />
					<TextField label="Type" source="type" />
					<TextField label="Status" source="status" />
					<TextField label="Total" source="total" />
					<TextField label="Tip" source="tip" />
					<TextField
						label="Discount"
						source="discount_total"
						emptyText="-"
					/>
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
