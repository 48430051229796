import React from "react";
import { Button, Avatar, CardHeader, CardMedia } from "@material-ui/core";
import { useRecordContext } from "ra-core";
import { EditButton } from "ra-ui-materialui";
import { CreateButton } from "react-admin";
import { Link } from "react-router-dom";
import { stringify } from "query-string";

const Component = props => {
	const record = useRecordContext();

	return (
		<>
			<CardHeader
				avatar={
					<Avatar aria-label="recipe" src={record?.image?.small}>
						R
					</Avatar>
				}
				titleTypographyProps={{ variant: "h5" }}
				title={record?.name}
			></CardHeader>
			<CardMedia
				image={record?.image?.large}
				title="Contemplative Reptile"
			/>
		</>
	);
};

export default Component;
