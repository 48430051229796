import { Grid } from "@material-ui/core";
import React from "react";
import { Show } from "react-admin";
import { Companies, CompanyInfo, Container, Map } from "./components";

export default props => {
	return (
		<Show
			{...props}
			actions={<></>}
			component={Container}
			title="Restaurant"
		>
			<>
				<Grid item xs={6} md={6}>
					<CompanyInfo />
				</Grid>
				<Grid item>
					<Map />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Companies />
				</Grid>
			</>
		</Show>
	);
};
