import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { TrendingFlat } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import { head, path, pluck, prop, uniq } from "ramda";
import { useEffect, useState } from "react";
import { useFormState } from "react-final-form";
import {
	ReferenceInput,
	SelectInput,
	SimpleForm,
	Toolbar,
	useDataProvider,
	Button as ReactAdminButton,
	useNotify
} from "react-admin";

const BulkMoveButton = ({
	basePath,
	label,
	resource,
	selectedIds,
	undoable,
	icon,
	...props
}) => {
	const notify = useNotify();
	const dataProvider = useDataProvider();

	const [productModalOpen, setProductModalOpen] = useState(false);
	const [productModalContent, setProductModalContent] = useState(<></>);

	const [checkinCount, setCheckinCount] = useState(null);
	const [currentStoreName, setCurrentStoreName] = useState(null);
	const [currentStoreId, setCurrentStoreId] = useState(null);

	const handleProductModalClose = () => {
		setProductModalOpen(false);
	};

	const openConfirm = async () => {
		const data = await dataProvider
			.getList("checkins", {
				filter: { checkinId: selectedIds },
				sort: { field: "id", order: "ASC" },
				pagination: { page: 1, perPage: 5 }
			})
			.catch(console.error);

		setCheckinCount(data ? data.data.length : 0);

		const currentStoreIds = data ? uniq(pluck("storeId", data.data)) : [];

		if (currentStoreIds.length === 1) {
			setCurrentStoreId(currentStoreIds[0]);
			const currentStoreName = await dataProvider
				.getOne("stores", {
					id: head(currentStoreIds)
				})
				.then(path(["data", "name"]))
				.catch(console.error);

			setCurrentStoreName(currentStoreName);
			setProductModalOpen(true);
		}
	};

	const CustomToolbar = props => {
		const { values } = useFormState();
		const { storeId } = values;

		const onSave = async () => {
			if (currentStoreId) {
				let successCount = 0;
				for (let checkinId of selectedIds) {
					await dataProvider.update("checkins", {
						id: checkinId,
						data: { storeId }
					});
					successCount += 1;
				}
				setProductModalOpen(false);
				notify(`${successCount} checkins moved.`, "success");
			}
		};

		return (
			<Toolbar {...props}>
				<Button variant="contained" color="primary" onClick={onSave}>
					Move checkins to store
				</Button>
			</Toolbar>
		);
	};

	return (
		<>
			<Dialog
				open={productModalOpen}
				onClose={handleProductModalClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					Select store
				</DialogTitle>
				<DialogContent>
					Move <b>{checkinCount}</b> checkins from store{" "}
					<b>{currentStoreName}</b> to store:
					<SimpleForm
						toolbar={<CustomToolbar />}
						initialValues={{ id: "123" }}
					>
						<ReferenceInput
							label="Stores"
							source="storeId"
							reference="stores"
							sort={false}
							perPage={200}
						>
							<SelectInput label="Store" optionText="name" />
						</ReferenceInput>
					</SimpleForm>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleProductModalClose}
						color="primary"
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<ReactAdminButton
				onClick={openConfirm}
				startIcon={<TrendingFlat />}
				color="default"
				label="Move to store"
			></ReactAdminButton>
		</>
	);
};

export default BulkMoveButton;
