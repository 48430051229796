import { Grid } from "@material-ui/core";
import React from "react";
import {
	DateField,
	FunctionField,
	NumberField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField
} from "react-admin";
export default props => {
	return (
		<Show {...props} actions={<></>} title="Payment">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<TabbedShowLayout>
						<Tab label="Payment">
							<TextField label="Payment ID" source="id" />
							<TextField
								label="External ID"
								source="external_id"
							/>
							<DateField
								label="Created"
								source="time_created"
								showTime={true}
							/>
							<TextField label="Provider" source="provider" />
							<TextField label="Type" source="type" />
							<TextField label="Status" source="status" />
							<NumberField label="Amount" source="amount" />
							<NumberField label="Fee" source="fee" />
							<TextField
								label="Country Code"
								source="country_code"
							/>
							<FunctionField
								label="Action"
								addLabel={false}
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.action,
											null,
											"  "
										)}
									</pre>
								)}
							/>
						</Tab>
					</TabbedShowLayout>
				</Grid>
			</>
		</Show>
	);
};
