import Group from "@material-ui/icons/Group";
import Show from "./Show";

export default {
	name: "welcome-tokens",
	options: {
		label: "Welcome Emails"
	},
	idPropName: "welcomeTokenId",
	show: Show,
	icon: Group
};
