import { CreateComponent } from "./CreateEdit";
import List from "./List";
import Show from "./Show";

export default {
	name: "checkins",
	idPropName: "checkin_id",
	show: Show,
	create: CreateComponent,
	//edit: EditComponent,
	list: List
};
