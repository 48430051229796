import { useMediaQuery } from "@material-ui/core";
import React from "react";
import {
	List,
	Datagrid,
	TextField,
	SimpleList,
	BooleanField,
	ReferenceManyField,
	SingleFieldList,
	ChipField,
	ReferenceField,
	ReferenceArrayField,
	DateField,
	Button,
	ShowButton
} from "react-admin";
import EuroIcon from "@material-ui/icons/Euro";
const DesktopGrid = props => (
	<Datagrid {...props} isRowSelectable={() => false} rowClick="show">
		<TextField label="Email" source="useremail" emptyText="-" />
		<DateField
			label="Create Date"
			showTime={true}
			source="createDate"
			emptyText="-"
		/>
		<DateField
			label="Last Sign in"
			showTime={true}
			source="lastSignInDate"
			emptyText="-"
		/>
		<ReferenceArrayField
			label="Merchants"
			reference="merchants"
			source="merchantIds"
		>
			<SingleFieldList linkType="show">
				<ChipField source="name" />
			</SingleFieldList>
		</ReferenceArrayField>

		<BooleanField source="active" />
		<BooleanField source="emailVerified" />
		<BooleanField source="superuser" />
	</Datagrid>
);

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => record.useremail}
		linkType="show"
	/>
);

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<List
			{...props}
			bulkActionButtons={false}
			perPage={25}
			sort={{ field: "useremail", order: "ASC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
