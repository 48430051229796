import {
	BooleanField,
	ReferenceField,
	Show,
	SimpleShowLayout,
	TextField
} from "ra-ui-materialui";
import { Title } from "./components";

export default props => (
	<Show {...props} title={<Title />}>
		<SimpleShowLayout>
			<ReferenceField source="storeId" reference="stores" link="show">
				<TextField source="name" emptyText="-" />
			</ReferenceField>
			<TextField source="id" sortable={false} emptyText="-" />
			<TextField source="name" sortable={false} emptyText="-" />
			<TextField
				source="externalTableId"
				sortable={false}
				emptyText="-"
			/>
			<BooleanField
				source="isPickup"
				sortable={false}
				label="Pickup"
				emptyText="-"
			/>
			<BooleanField source="alert" sortable={false} emptyText="-" />
			<BooleanField source="sound" sortable={false} emptyText="-" />
			<TextField source="alertDelay" sortable={false} emptyText="-" />
		</SimpleShowLayout>
	</Show>
);
