import React from "react";
import { NumberInput } from "react-admin";
import { formatInputProcentage, parseInputProcentage } from "./functions";

const Component = props => {
	return (
		<NumberInput
			format={formatInputProcentage}
			parse={parseInputProcentage}
			{...props}
		/>
	);
};

export default Component;
