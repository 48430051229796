import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import { useMemo } from "react";
import {
	AutocompleteArrayInput,
	FilterList,
	FilterListItem,
	ReferenceArrayInput,
	useListFilterContext
} from "react-admin";
import { Form } from "react-final-form";

const Card = withStyles(theme => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			order: -1, // display on the left rather than on the right of the list
			width: "20em",
			marginRight: "1em"
		},
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	}
}))(MuiCard);

const FilterMerchants = props => {
	const { source, ...rest } = props;
	const { filterValues, setFilters } = useListFilterContext();

	const initialValues = useMemo(
		() => ({
			[source]: filterValues[source]
		}),
		[filterValues, source]
	);

	const onSubmit = () => undefined;

	const onChange = merchantIds => {
		console.log("object", merchantIds, {
			...filterValues,
			[source]: merchantIds
		});
		setFilters({ ...filterValues, [source]: merchantIds }, null);
	};
	return (
		<Form initialValues={initialValues} onSubmit={onSubmit}>
			{() => (
				<ReferenceArrayInput
					onChange={onChange}
					setFilter={() => ({})}
					sort={false}
					shouldRenderSuggestions={true}
					perPage={10000}
					source={source}
					label={""}
					reference="merchants"
				>
					<AutocompleteArrayInput source="id" />
				</ReferenceArrayInput>
			)}
		</Form>
	);
};

export default () => (
	<Card>
		<CardContent>
			<FilterList label="Company Type">
				<FilterListItem label="AB" value={{ type: "ab" }} />
				<FilterListItem label="SK" value={{ type: "sk" }} />
			</FilterList>
			<FilterList label="Year Founded">
				<FilterListItem label="2021" value={{ year_founded: "2021" }} />
				<FilterListItem label="2020" value={{ year_founded: "2020" }} />
				<FilterListItem label="2019" value={{ year_founded: "2019" }} />
				<FilterListItem label="2018" value={{ year_founded: "2018" }} />
				<FilterListItem label="2017" value={{ year_founded: "2017" }} />
				<FilterListItem label="2016" value={{ year_founded: "2016" }} />
			</FilterList>
			<FilterList label="Revenue">
				<FilterListItem
					label="1 - 10M"
					value={{ revenue_gte: 1000 * 1, revenue_lte: 1000 * 10 }}
				/>
				<FilterListItem
					label="10 - 15M"
					value={{ revenue_gte: 1000 * 10, revenue_lte: 1000 * 15 }}
				/>
				<FilterListItem
					label="15 - 20M"
					value={{ revenue_gte: 1000 * 15, revenue_lte: 1000 * 20 }}
				/>
				<FilterListItem
					label="20 - 30M"
					value={{ revenue_gte: 1000 * 20, revenue_lte: 1000 * 30 }}
				/>
				<FilterListItem
					label="30 - 50M"
					value={{ revenue_gte: 1000 * 30, revenue_lte: 1000 * 50 }}
				/>
				<FilterListItem
					label="50 - 100M"
					value={{ revenue_gte: 1000 * 50, revenue_lte: 1000 * 100 }}
				/>
				<FilterListItem
					label="100 - 500M"
					value={{ revenue_gte: 1000 * 100, revenue_lte: 1000 * 500 }}
				/>
				<FilterListItem
					label="500M+"
					value={{ revenue_gte: 1000 * 500 }}
				/>
			</FilterList>
			<FilterList label="County">
				<FilterListItem
					label="Stockholm"
					value={{ county: "Stockholm" }}
				/>
				<FilterListItem
					label="Göteborg"
					value={{ county: "Göteborg" }}
				/>
				<FilterListItem label="Malmö" value={{ county: "Malmö" }} />
				<FilterListItem label="Uppsala" value={{ county: "Uppsala" }} />
				<FilterListItem label="Örebro" value={{ county: "Örebro" }} />
				<FilterListItem
					label="Linköping"
					value={{ county: "Linköping" }}
				/>
				<FilterListItem
					label="Helsingborg"
					value={{ county: "Helsingborg" }}
				/>
				<FilterListItem label="Solna" value={{ county: "Solna" }} />
				<FilterListItem
					label="Västerås"
					value={{ county: "Västerås" }}
				/>
				<FilterListItem
					label="Jönköping"
					value={{ county: "Jönköping" }}
				/>
				{/*<FilterListItem label="Gävle" value={{ county: "Gävle" }} />
				<FilterListItem
					label="Norrköping"
					value={{ county: "Norrköping" }}
				/>
				<FilterListItem
					label="Karlstad"
					value={{ county: "Karlstad" }}
				/>
				<FilterListItem label="Lund" value={{ county: "Lund" }} />
				<FilterListItem label="Umeå" value={{ county: "Umeå" }} />
				<FilterListItem label="Borås" value={{ county: "Borås" }} />
				<FilterListItem
					label="Sundsvall"
					value={{ county: "Sundsvall" }}
				/>*/}
			</FilterList>
		</CardContent>
	</Card>
);
