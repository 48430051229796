import { Card, CardHeader } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import React from "react";
import {
	FunctionField,
	NumberField,
	ReferenceManyField,
	TextField,
	useShowController
} from "react-admin";

const OrderItemDetails = props => {
	const { record } = useShowController(props);

	if (!record) return null;

	return (
		<>
			<div>
				Deal: <pre>{JSON.stringify(record.action)}</pre>
			</div>
		</>
	);
};

const Component = props => {
	return (
		<Card>
			<CardHeader title="Items"></CardHeader>
			<ReferenceManyField
				label="Order Items"
				reference="orderitems"
				target="order_id"
				source="id"
				sort={false}
			>
				<ResponsiveDataGrid
					rowClick={false}
					empty={
						<EmptyCard text="No order items exists for this order" />
					}
					primaryText={item => `${item.product?.name}`}
					secondaryText={item => `Price: ${item.product_price}`}
					tertiaryText={item => item.note}
				>
					<FunctionField
						label="Product"
						render={record => record.product?.name}
					/>
					<NumberField label="Product Price" source="product_price" />
					<NumberField label="Paid Amount" source="paid_amount" />
					<FunctionField
						label="VAT Rate"
						render={record => `${record.product_vat_rate * 100} %`}
					/>
					<TextField label="Note" source="note" />
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
