import Icon from "@material-ui/icons/Business";
import { CreateComponent, EditComponent } from "./CreateEdit";
import List from "./List";
import Show from "./Show";

export default {
	name: "companies",
	idPropName: "company_id",
	icon: Icon,
	list: List,
	show: Show,
	edit: EditComponent,
	create: CreateComponent
};
