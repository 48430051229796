import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { curry, pick, pickAll } from "ramda";
import React, { useCallback } from "react";
import {
	ReferenceInput,
	required,
	SaveButton,
	SelectInput,
	SimpleForm,
	Toolbar,
	useGetList,
	useGetOne,
	useRecordContext
} from "react-admin";
import ConfigInput from "./ConfigInput";
import FunctionsInput from "./FunctionsInput";

const transformDataBeforeSave = curry((schema, data) => {
	// Make sure only props that exists in the schema is saved, for example when props
	// are removed from schema or when you switch provider.
	const configProps = Object.keys(schema[data.provider].config || {});
	const credentialsProps = Object.keys(
		schema[data.provider].credentials || {}
	);

	const strippedData = {
		...data,
		config: pickAll(configProps, data.config || {}),
		credentials: pickAll(credentialsProps, data.credentials || {})
	};

	return strippedData;
});

const PostEditToolbar = props => {
	const { schema } = props;

	return (
		<Toolbar {...props} alwaysEnableSaveButton>
			<SaveButton transform={transformDataBeforeSave(schema)} />
			{props.record.isAvailable && (
				<Alert severity="warning">
					Disabling {props.record.id} will affect the entire system
					(all merchants).
				</Alert>
			)}
		</Toolbar>
	);
};

const Component = props => {
	const { merchantId } = useRecordContext(props);
	const { type } = props;

	const { data: schema, loading, error } = useGetOne(
		"integration-schema",
		"A",
		{},
		{},
		{}
	);

	const { data: stores } = useGetList(
		"stores",
		{ page: 1, perPage: 100 },
		{
			storeId: "desc"
		},
		{
			merchantId
		}
	);

	const getIntegrations = useCallback(() => {
		if (!schema) return [];

		return Object.keys(schema)
			.filter(key => key !== "id")
			.map(id => ({
				id: id,
				name: schema[id].name
			}))
			.filter(p => p.name);
	}, [schema]);

	return schema && stores ? (
		<SimpleForm
			toolbar={<PostEditToolbar schema={schema} />}
			{...props}
			redirect="show"
		>
			<ReferenceInput
				label="Merchant"
				source="merchantId"
				reference="merchants"
				disabled={type === "edit"}
				allowEmpty={false}
				sort={false}
				validate={required("Merchant is required")}
			>
				<SelectInput label="Store" optionText="name" />
			</ReferenceInput>
			<SelectInput
				source="provider"
				allowEmpty={false}
				choices={getIntegrations()}
			/>
			<Typography variant="h6">Config</Typography>
			<ConfigInput prefix="config" schema={schema || {}} />
			<Typography variant="h6">Credentials</Typography>
			<ConfigInput prefix="credentials" schema={schema || {}} />
			<Typography variant="h6">Enabled</Typography>
			<FunctionsInput
				{...props}
				name="functions"
				source="functions"
				stores={stores}
				schema={schema || {}}
			/>
		</SimpleForm>
	) : (
		<div></div>
	);
};

export default Component;
