import { Grid } from "@material-ui/core";
import React from "react";
import { Show } from "react-admin";
import { CompanyInfo, Container, Map } from "./components";

export default props => {
	return (
		<Show {...props} actions={<></>} component={Container} title="Company">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<CompanyInfo />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Map />
				</Grid>
			</>
		</Show>
	);
};
