import { curry } from "ramda";

const domain = {
	local: `http://${process.env.REACT_APP_LOCAL_IP}:6003`,
	development: "https://tab.dev.passbuy.com",
	staging: "https://tab.staging.passbuy.com",
	production: "https://tab.passbuy.com"
}[process.env.REACT_APP_ENVIRONMENT || "local"];

export default curry(
	(product, checkinId) =>
		`${domain}/?checkinId=${checkinId}&product=${product}`
);
