import { Grid } from "@material-ui/core";
import React from "react";
import { Show, useRecordContext } from "react-admin";
import {
	Container,
	DashboardUsers,
	FortnoxReports,
	Integrations,
	MerchantInfo,
	MerchantOrders,
	Stores,
	StripePayouts
} from "./components";

const Title = ({ record: { name } }) => {
	return <span>{`Merchant / ${name}`}</span>;
};

export default props => {
	const record = useRecordContext(props);

	return (
		<Show {...props} component={Container} title={<Title />}>
			<>
				<Grid item xs={12} md={12} lg={12} xl={6}>
					<MerchantInfo />
				</Grid>

				<Grid item xs={12} md={12} lg={12} xl={6}>
					<Stores />
					<br />
					<Integrations />
					<br />
					<DashboardUsers />
					<br />
					<MerchantOrders />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<FortnoxReports />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<StripePayouts />
				</Grid>
			</>
		</Show>
	);
};
