import {
	Card,
	CardContent,
	CardHeader,
	useMediaQuery
} from "@material-ui/core";
import MUITimeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import _ from "lodash";
import Moment from "moment-timezone";
import * as React from "react";
import { useGetList, useRecordContext } from "react-admin";

const formatOrderTickets = payments =>
	Object.keys(payments)
		.map(p => payments[p])
		.flatMap(p => {
			const tickets = [];
			let status = "pending";
			if (p.timeDone) status = "done";

			if (p.timeCreated)
				tickets.push({
					header: `${p.stationName} ticket created`,
					text: `${p.stationName}`,
					status,
					time: Moment(p.timeCreated).tz("Europe/Stockholm")
				});
			if (p.timeDone)
				tickets.push({
					header: `${p.stationName} ticket completed`,
					text: `${p.stationName}`,
					status,
					time: Moment(p.timeDone).tz("Europe/Stockholm")
				});

			return tickets;
		});

const formatOrderTicketExports = (orderTickets, orderTicketExports) =>
	Object.keys(orderTicketExports)
		.map(p => orderTicketExports[p])
		.flatMap(p => {
			const tickets = [];
			const orderTicket = orderTickets[p.id] || {};
			let status = "pending";
			if (p.timeCompleted) status = "done";
			if (p.timeError) status = "failed";
			if (p.timeStarted)
				tickets.push({
					header: `${orderTicket.stationName} ticket export created`,
					status,
					time: Moment(p.timeStarted).tz("Europe/Stockholm")
				});
			if (p.timeError)
				tickets.push({
					header: `${orderTicket.stationName} ticket export failed`,
					status,
					time: Moment(p.timeError).tz("Europe/Stockholm")
				});
			if (p.timeCompleted)
				tickets.push({
					header: `${orderTicket.stationName} ticket export completed`,
					status,
					time: Moment(p.timeCompleted).tz("Europe/Stockholm")
				});

			return tickets;
		});

const formatOrderExports = orderExports =>
	Object.keys(orderExports)
		.map(p => orderExports[p])
		.flatMap(p => {
			const tickets = [];
			let status = "pending";
			if (p.timeCompleted) status = "done";
			if (p.timeError) status = "failed";

			if (p.timeStarted)
				tickets.push({
					header: `Export to ${p.provider} started`,
					status,
					time: Moment(p.timeStarted).tz("Europe/Stockholm")
				});
			if (p.timeCompleted)
				tickets.push({
					header: `Export to ${p.provider} completed`,
					status,
					time: Moment(p.timeCompleted).tz("Europe/Stockholm")
				});
			if (p.timeError)
				tickets.push({
					header: `Export to ${p.provider} failed`,
					status,
					time: Moment(p.timeError).tz("Europe/Stockholm")
				});

			return tickets;
		});

const formatOrderStatusTransitions = (order, transitions) =>
	Object.keys(transitions)
		.map(p => transitions[p])
		.flatMap(p => {
			const items = [];
			let status = "pending";
			if (order.status === "COMPLETED") status = "done";
			else if (["FAILED"].includes(order.status)) status = "failed";

			if (!p.from_status) {
				items.push({
					header: `Order created with status ${p.to_status}`,
					status,
					time: Moment(p.time_created).tz("Europe/Stockholm")
				});
			} else {
				items.push({
					header: `Order status changed to ${p.to_status}`,
					status,
					time: Moment(p.time_created).tz("Europe/Stockholm")
				});
			}

			return items;
		});

const formatPaymentStatusTransitions = (payments, transitions) =>
	Object.keys(transitions)
		.map(p => transitions[p])
		.flatMap(p => {
			const items = [];
			let status = "pending";
			const payment = payments[p.payment_id];

			if (payment.status === "COMPLETED") status = "done";
			else if (
				["FAILED", "TIMEOUT", "CANCELED", "DECLINED"].includes(
					payment.status
				)
			)
				status = "failed";

			if (!p.from_status) {
				items.push({
					header: `Payment #${p.payment_id} created with status ${p.to_status}`,
					status,
					time: Moment(p.time_created).tz("Europe/Stockholm")
				});
			} else {
				items.push({
					header: `Payment #${p.payment_id} status changed to ${p.to_status}`,
					status,
					time: Moment(p.time_created).tz("Europe/Stockholm")
				});
			}

			return items;
		});

const getDotColor = item => {
	if (item.status === "done") return "#2bb72b";
	if (item.status === "pending") return "orange";
	if (item.status === "failed") return "red";

	return "lightgray";
};

const Timeline = props => {
	const record = useRecordContext(props);
	if (!record) return null;

	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	const orderId = record.id;

	const { data: payments, loading: paymentsLoading } = useGetList(
		"payments",
		{ page: 1, perPage: 100 },
		{},
		{
			order_id: orderId
		}
	);
	const { data: orderTickets, loading: orderTicketsLoading } = useGetList(
		"ordertickets",
		{ page: 1, perPage: 100 },
		{},
		{
			orderId: orderId
		}
	);
	const { data: orderExports, loading: orderExportsLoading } = useGetList(
		"orderexports",
		{ page: 1, perPage: 100 },
		{},
		{
			orderId: orderId
		}
	);

	const {
		data: orderTicketExports,
		loading: orderTicketExportsLoading
	} = useGetList(
		"orderticketexports",
		{ page: 1, perPage: 100 },
		{},
		{
			orderTicketId: Object.keys(orderTickets)
		}
	);

	const {
		data: orderStatusTransitions,
		loading: orderStatusTransitionsLoading
	} = useGetList(
		"orderstatustransitions",
		{ page: 1, perPage: 100 },
		{},
		{
			order_id: orderId
		}
	);

	const {
		data: paymentStatusTransitions,
		loading: paymentStatusTransitionsLoading
	} = useGetList(
		"paymentstatustransitions",
		{ page: 1, perPage: 100 },
		{},
		{
			payment_id_in: Object.keys(payments).concat(["0"])
		}
	);

	const loading =
		paymentsLoading ||
		orderTicketsLoading ||
		orderExportsLoading ||
		orderTicketExportsLoading ||
		orderStatusTransitionsLoading ||
		paymentStatusTransitionsLoading;

	const events = _.orderBy(
		[
			//...formatPayments(payments),
			...formatOrderTickets(orderTickets),
			...formatOrderExports(orderExports),
			...formatOrderStatusTransitions(record, orderStatusTransitions),
			...formatPaymentStatusTransitions(
				payments,
				paymentStatusTransitions
			),
			...formatOrderTicketExports(orderTickets, orderTicketExports)
		],
		p =>
			`${p.time.format("YYYYMMDDHH:mm:ss:SSS")}${
				/^O/.test(p.header) ? "X" : "A"
			}`,
		["asc"]
	);

	const items = [...events];

	if (loading) {
		return (
			<Card>
				<CardHeader title="Timeline"></CardHeader>
				<CardContent>...</CardContent>
			</Card>
		);
	}

	if (Object.keys(orderStatusTransitions).length === 0) {
		return (
			<Card>
				<CardHeader title="Timeline"></CardHeader>
				<CardContent>
					Order is missing order status transitions
				</CardContent>
			</Card>
		);
	}

	return (
		<Card>
			<CardHeader title="Timeline"></CardHeader>
			<MUITimeline
				style={{
					marginTop: 0,
					width: isXSmall ? 380 : 500,
					padding: 0,
					marginBottom: 0
				}}
				position="alternate"
			>
				{items.map((i, ix) => (
					<TimelineItem style={{ minHeight: 45 }}>
						<TimelineOppositeContent
							style={{
								fontSize: 13,
								marginTop: 1,
								flex: "inherit",
								width: 50
							}}
							color="text.secondary"
						>
							{i.time.format("HH:mm:ss")}
						</TimelineOppositeContent>

						<TimelineSeparator>
							<TimelineDot
								style={{
									boxShadow: "none",
									marginTop: 10,
									padding: 3,
									backgroundColor: getDotColor(i)
								}}
							/>
							{ix !== items.length - 1 && (
								<TimelineConnector
									style={{
										width: 2,
										height: 1,
										background: "#efefef"
									}}
								/>
							)}
						</TimelineSeparator>
						<TimelineContent style={{ fontSize: 14 }}>
							{i.header}
						</TimelineContent>
					</TimelineItem>
				))}
			</MUITimeline>
		</Card>
	);
};

export default Timeline;
