import React from "react";
import { Card, CardHeader } from "@material-ui/core";
import { Form } from "./components";
import { Create, Edit } from "ra-ui-materialui";

const CreateComponent = props => {
	return (
		<Card>
			<CardHeader title="Create Payment Provider"></CardHeader>
			<Create
				redirect=""
				mutationMode="pessimistic"
				actions={false}
				{...props}
			>
				{<Form {...props} />}
			</Create>
		</Card>
	);
};

const EditComponent = props => {
	return (
		<Card>
			<CardHeader title="Edit"></CardHeader>
			<Edit mutationMode="pessimistic" actions={false} {...props}>
				<Form {...props} />
			</Edit>
		</Card>
	);
};

export { CreateComponent, EditComponent };
