import EuroIcon from "@material-ui/icons/Euro";
import { CreateComponent, EditComponent } from "./CreateEdit";
import List from "./List";
import Show from "./Show";

export default {
	name: "stations",
	idPropName: "stationId",
	icon: EuroIcon,
	list: List,
	show: Show,
	create: CreateComponent,
	edit: EditComponent
};
