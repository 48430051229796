import { Card, CardHeader, Typography } from "@material-ui/core";
import React from "react";
import {
	FunctionField,
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";
import ReactStars from "react-rating-stars-component";

const addhttp = url => {
	if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
		url = "http://" + url;
	}
	return url;
};

const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<CardHeader
				title={
					<div
						style={{
							display: "flex",
							justifyContent: "space-between"
						}}
					>
						{record?.name}
						<div>
							<ReactStars
								size={30}
								count={5}
								value={
									Number(record?.rating) === "NaN"
										? 0
										: Number(record?.rating)
								}
								isHalf={true}
								char="✪"
								edit={false}
							/>
							<div
								style={{
									fontSize: 12,
									display: "flex",
									justifyContent: "flex-end"
								}}
							>
								{record.rating_count} votes ({record.rating})
							</div>
						</div>
					</div>
				}
			></CardHeader>
			<TabbedShowLayout>
				<Tab label="Company">
					<TextField label="ID" source="id"></TextField>
					<TextField label="Address" source="address"></TextField>
					<FunctionField
						label="Website"
						render={record => (
							<a href={addhttp(record.website)} target="_blank">
								{record.website}
							</a>
						)}
					></FunctionField>
					<TextField
						label="phone number"
						source="phone_no"
					></TextField>
				</Tab>
				<Tab label="Details">
					<FunctionField
						label=" "
						render={record => (
							<>
								{record.details.map(d => (
									<div style={{ marginBottom: 8 }}>
										<Typography variant="subtitle2">
											{d.type}
										</Typography>
										{d.props.map((p, i) => (
											<div
												key={`${p}-${i}`}
												style={{
													display: "flex"
												}}
											>
												<div
													style={{
														marginRight: 8,

														width: 120
													}}
												>
													{p.name}:
												</div>
												<div>{p.value}</div>
											</div>
										))}
									</div>
								))}
							</>
						)}
					/>
				</Tab>
				<Tab label="Latest reviews">
					<FunctionField
						label=" "
						render={record => (
							<>
								{record.latest_reviews.map((r, i) => (
									<div style={{ marginBottom: 8 }}>
										<div
											style={{
												marginRight: 8,
												padding: 16,

												borderBottom:
													"1px solid #eaeaea"
											}}
										>
											{r}:
										</div>
									</div>
								))}
							</>
						)}
					/>
				</Tab>
			</TabbedShowLayout>
		</Card>
	);
};

export default Component;
