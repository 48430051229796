import React from "react";
import {
	SimpleForm,
	SelectInput,
	TextInput,
	ReferenceInput,
	BooleanInput,
	useRecordContext,
	required,
	NumberInput
} from "react-admin";
import { floor, isNumber, validateInput } from "utils";
import { PercentageField } from "modules/core/components";
import { useFormState } from "react-final-form";
import { anyPass, compose, defaultTo, gte, lte, startsWith } from "ramda";

const ESInput = props => {
	const { values } = useFormState();
	if (values.type === "FLAT_PERCENTAGE") {
		return (
			<>
				<PercentageField
					label="Percentage"
					source="properties.percentage"
					helperText="Amount in % of the cart amount"
					margin="normal"
					validate={[
						validateInput(lte(0), "Must be 0% or greater"),
						validateInput(gte(0.5), "Must be 50% or less"),
						required("Cart percentage is required")
					]}
				/>
			</>
		);
	}

	if (values.type === "FLAT_AMOUNT") {
		return (
			<>
				<NumberInput
					label="Amount"
					source="properties.amount"
					helperText="Amount to subtract from the cart amount"
					margin="normal"
					validate={[required("Amount is required")]}
				/>
				<br />
				<NumberInput
					label="Minimum Cart Total"
					source="properties.minimumCartTotal"
					helperText="Minimum cart amount for the deal to be applied"
					margin="normal"
					allowEmpty={true}
				/>
			</>
		);
	}

	return <></>;
};

const Component = props => {
	const record = useRecordContext(props);
	const { type } = props;
	return (
		<SimpleForm {...props} redirect="show">
			<ReferenceInput
				label="Stores"
				source="storeId"
				reference="stores"
				disabled={type === "edit"}
				allowEmpty={false}
				sort={false}
				validate={required("Store is required")}
			>
				<SelectInput label="Store" optionText="name" />
			</ReferenceInput>
			<BooleanInput source="active" />
			<BooleanInput source="firstTimeUsersOnly" />
			<TextInput
				source="description"
				validate={[required("Description is required")]}
			/>
			<SelectInput
				source="category"
				allowEmpty={false}
				choices={[
					{
						id: "CART",
						name: "Cart"
					}
				]}
			/>
			<SelectInput
				source="type"
				allowEmpty={false}
				choices={[
					{
						id: "FLAT_PERCENTAGE",
						name: "Flat Percentage"
					},
					{
						id: "FLAT_AMOUNT",
						name: "Flat Amount"
					}
				]}
			/>
			<ESInput />
		</SimpleForm>
	);
};

export default Component;
