import { useMediaQuery } from "@material-ui/core";
import FeeField from "components/fields/FeeField";
import React from "react";
import {
	List,
	Datagrid,
	TextField,
	SimpleList,
	Filter,
	TextInput,
	BooleanInput,
	BooleanField,
	ReferenceArrayField
} from "react-admin";

const DesktopGrid = props => (
	<Datagrid {...props} isRowSelectable={() => false} rowClick="show">
		<TextField fullWidth={true} source="name" emptyText="-" />
		<BooleanField
			label="External Sync"
			source="externalProductSync"
			emptyText="-"
		/>
	</Datagrid>
);

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => record.name}
		linkType="show"
	/>
);

const MerchantTopFilters = props => {
	return (
		<Filter {...props}>
			<BooleanInput
				label="Inactive"
				source="timeInactivated_nn"
				alwaysOn
			/>
		</Filter>
	);
};

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<List
			{...props}
			filters={<MerchantTopFilters />}
			bulkActionButtons={false}
			perPage={25}
			filterDefaultValues={{ timeInactivated_nn: false }}
			sort={{ field: "name", order: "ASC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
