const { useRecordContext } = require("ra-core");
export default ({ source, formatFunction }) => {
	const record = useRecordContext();
	const url = formatFunction && formatFunction(record[source]);
	return (
		<a href={url} style={{ fontSize: "13px", textDecoration: "none" }}>
			{record && url}
		</a>
	);
};
