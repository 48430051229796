import React from "react";
import { BooleanField, Datagrid, List, TextField } from "react-admin";

export default props => (
	<List {...props} perPage={25} sort={{ field: "merchantId", order: "ASC" }}>
		<Datagrid rowClick="show">
			<TextField source="name" emptyText="-" sortable={true} />
			<BooleanField source="alert" emptyText="-" sortable={true} />
			<BooleanField source="isPickup" emptyText="-" sortable={true} />
			<TextField source="alertDelay" emptyText="-" sortable={true} />
			<TextField source="sound" emptyText="-" sortable={true} />
			<TextField source="externalTableId" emptyText="-" sortable={true} />
		</Datagrid>
	</List>
);
