import { Card, CardHeader } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { useTheme } from "@material-ui/core/styles";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import React from "react";
import {
	DateField,
	NumberField,
	ReferenceManyField,
	TextField
} from "react-admin";

const rowStyle = selectedRow => record => {
	const theme = useTheme();
	let style = {};
	if (!record) {
		return style;
	}
	if (selectedRow && selectedRow === record.id) {
		style = {
			...style,
			backgroundColor: theme.palette.action.selected
		};
	}

	if (["COMPLETED"].includes(record.status))
		return {
			...style,
			borderLeftColor: green[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (
		["INITIALIZED", "AWAITING_USER_AUTHORIZATION", "PENDING"].includes(
			record.status
		)
	)
		return {
			...style,
			borderLeftColor: orange[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (["FAILED", "TIMEOUT", "CANCELED"].includes(record.status))
		return {
			...style,
			borderLeftColor: red[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	return style;
};

const Component = ({ selectedRow, ...props }) => {
	return (
		<Card>
			<CardHeader title="Payments"></CardHeader>
			<ReferenceManyField
				label="Payments"
				reference="payments"
				target="order_id"
				source="id"
				primaryText={payment => `${payment.provider}`}
				tertiaryText={payment => `${payment.amount} kr`}
				secondaryText={payment => `${payment.status}`}
				sort={{ field: "time_created", order: "DESC" }}
			>
				<ResponsiveDataGrid
					rowStyle={rowStyle(selectedRow)}
					primaryText={payment => `Payment ${payment.id}`}
					empty={
						<EmptyCard text="No order items exists for this order" />
					}
					rowClick="show"
				>
					<TextField label="Payment ID" source="id" />
					<DateField
						label="Created"
						source="time_created"
						showTime={true}
					/>
					<TextField label="Provider" source="provider" />
					<TextField label="Type" source="type" />
					<TextField label="Status" source="status" />
					<NumberField label="Amount" source="amount" />
					<NumberField label="Fee" source="fee" />
					<TextField label="Country Code" source="country_code" />
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
