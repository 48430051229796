import { useMediaQuery } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import React from "react";
import { Datagrid, SimpleList } from "react-admin";

const MobileGrid = ({
	primaryText = () => null,
	secondaryText = () => null,
	tertiaryText = () => null,
	...props
}) => (
	<SimpleList
		{...props}
		primaryText={primaryText}
		secondaryText={secondaryText}
		tertiaryText={tertiaryText}
		linkType="show"
	></SimpleList>
);

export default ({ children, emptyText = "No items exist", ...props }) => {
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	return isXSmall ? (
		<MobileGrid {...props} />
	) : (
		<Datagrid empty={<EmptyCard text={emptyText} />} {...props}>
			{children}
		</Datagrid>
	);
};
