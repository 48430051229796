import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField
} from "ra-ui-materialui";
import { Title } from "./components";

export default props => (
	<Show {...props} title={<Title />}>
		<SimpleShowLayout>
			<ReferenceField
				source="merchantId"
				reference="merchants"
				link="show"
			>
				<TextField source="name" emptyText="-" />
			</ReferenceField>
			<TextField source="id" sortable={false} emptyText="-" />
		</SimpleShowLayout>
	</Show>
);
