import { Grid } from "@material-ui/core";
import React from "react";
import {
	DateField,
	FunctionField,
	NumberField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField
} from "react-admin";
export default props => {
	return (
		<Show {...props} actions={<></>} title="Order Export">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<TabbedShowLayout>
						<Tab label="Order Export">
							<TextField label="ID" source="id" />
							<TextField label="Provider" source="provider" />
							<DateField
								label="Started"
								source="timeStarted"
								showTime={true}
							/>
							<DateField
								label="Completed"
								source="timeCompleted"
								showTime={true}
							/>
							<TextField label="Status" source="status" />
							<NumberField label="Retries" source="retries" />
							<FunctionField
								label="Response"
								addLabel={false}
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.response,
											null,
											"  "
										)}
									</pre>
								)}
							/>
							<FunctionField
								label="Error"
								addLabel={false}
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.error,
											null,
											"  "
										)}
									</pre>
								)}
							/>
						</Tab>
					</TabbedShowLayout>
				</Grid>
			</>
		</Show>
	);
};
