import firebase from "firebase/app";
import "firebase/auth";

const initFirebase = () => {
	const isAppInitialized = firebase.apps.length > 0;

	if (!isAppInitialized) {
		return firebase.initializeApp({
			apiKey: "AIzaSyDhaVPwiN42DYlLp3GHfNydoAKLdbrN-ag",
			authDomain: "backoffice-771e5.firebaseapp.com",
			projectId: "backoffice-771e5",
			storageBucket: "backoffice-771e5.appspot.com",
			messagingSenderId: "474709736467",
			appId: "1:474709736467:web:d4a6ce06073d426c1893e6"
		});
	}

	return firebase.app();
};

export default initFirebase();
