import React from "react";
import {
	SimpleForm,
	BooleanInput,
	SelectInput,
	Toolbar,
	SaveButton,
	useRecordContext
} from "react-admin";
import Alert from "@material-ui/lab/Alert";

const PostEditToolbar = props => {
	return (
		<Toolbar {...props} alwaysEnableSaveButton>
			<SaveButton />
			{props.record.isAvailable && (
				<Alert severity="warning">
					Disabling {props.record.id} will affect the entire system
					(all merchants).
				</Alert>
			)}
		</Toolbar>
	);
};

const Component = props => {
	const record = useRecordContext(props);
	return (
		<SimpleForm toolbar={<PostEditToolbar />} {...props} redirect="show">
			<BooleanInput source="isAvailable" />
			{record.id === "swish" && (
				<SelectInput
					source="messageType"
					allowEmpty={true}
					choices={[
						{
							id: "SWISH_UNAVAILABLE",
							name: "SWISH IS UNAVAILABLE"
						}
					]}
				/>
			)}

			{record.id === "stripe" && (
				<SelectInput
					source="messageType"
					allowEmpty={true}
					choices={[
						{
							id: "STRIPE_UNAVAILABLE",
							name: "STRIPE IS UNAVAILABLE"
						}
					]}
				/>
			)}
		</SimpleForm>
	);
};

export default Component;
