import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toHumanString } from "human-readable-numbers";
import { path } from "ramda";
import React from "react";
import {
	Datagrid,
	Filter,
	List,
	TextField,
	TextInput,
	TopToolbar,
	useRecordContext
} from "react-admin";
import FilterSidebar from "./FilterSidebar";
import rowStyle from "./RowStyle";

const useListStyles = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid"
	},
	headerCell: {
		padding: "6px 8px 6px 8px"
	},
	rowCell: {
		padding: "6px 8px 6px 8px"
	},
	comment: {
		maxWidth: "18em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	}
});

const RevenueField = props => {
	const { source } = props;
	const record = useRecordContext(props);

	if (!record[source]) return "N/A";
	const value = path([source], record) * 1000;

	return `${toHumanString(value)}`;
};
const DesktopGrid = ({ selectedRow, ...props }) => {
	const classes = useListStyles();
	return (
		<Datagrid
			{...props}
			isRowSelectable={() => false}
			rowClick="show"
			rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell
			}}
		>
			<TextField source="name" emptyText="" />
			<TextField source="county" emptyText="" />
			<TextField source="year_founded" emptyText="" />
			<RevenueField source="revenue" />
		</Datagrid>
	);
};

const OrderTopFilters = props => {
	return (
		<Filter {...props}>
			<TextInput
				autoFocus={true}
				label="Name"
				source="name_lke"
				alwaysOn
			/>
			<TextInput
				autoFocus={true}
				label="County"
				source="county_lke"
				alwaysOn
			/>
			<TextInput
				autoFocus={true}
				label="Org No"
				source="orgnr"
				alwaysOn
			/>
		</Filter>
	);
};

const ListActions = () => <TopToolbar></TopToolbar>;

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	return (
		<List
			{...props}
			actions={<ListActions />}
			aside={<FilterSidebar />}
			filters={<OrderTopFilters />}
			bulkActionButtons={false}
			sort={{ field: "name", order: "ASC" }}
			perPage={25}
		>
			<DesktopGrid />
		</List>
	);
};
