import EuroIcon from "@material-ui/icons/Euro";
import List from "./List";
import Show from "./Show";
import Group from "@material-ui/icons/Group";
import { CreateComponent, EditComponent } from "./CreateEdit";

export default {
	name: "dashboard-users",
	options: {
		label: "Dashboard Users"
	},
	idPropName: "uid",
	icon: Group,
	list: List,
	show: Show,
	create: CreateComponent,
	edit: EditComponent
};
