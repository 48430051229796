import React from "react";
import { Card, CardHeader } from "@material-ui/core";
import { Form } from "./components";
import { Create } from "ra-ui-materialui";
import queryString from "query-string";

const CreateComponent = props => {
	const { multiple } = props.location
		? queryString.parse(props.location.search)
		: {};

	return (
		<Card>
			<CardHeader title="Import Products & Menu"></CardHeader>
			<Create
				redirect=""
				mutationMode="pessimistic"
				actions={false}
				{...props}
			>
				<Form {...props} />
			</Create>
		</Card>
	);
};

export { CreateComponent };
