import { Show, SimpleShowLayout, BooleanField, TextField } from "react-admin";
import { Title } from "./components";
import { Card } from "@material-ui/core";
export default props => (
	<Card>
		<Show {...props} title={<Title />}>
			<SimpleShowLayout>
				<TextField source="id" emptyText="-" sortable={true} />
				<BooleanField source="isAvailable" />
				<TextField
					label="Message type"
					source="message.type"
					emptyText="-"
					sortable={true}
				/>
				<TextField
					label="Message name"
					source="message.name"
					emptyText="-"
					sortable={true}
				/>
				<TextField
					label="Message description"
					source="message.description"
					emptyText="-"
					sortable={true}
				/>
			</SimpleShowLayout>
		</Show>
	</Card>
);
