import {
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
	Link,
	ReferenceInput,
	SelectInput,
	useCreate,
	useGetList,
	useGetOne,
	useUpdate
} from "react-admin";
import { useFormState } from "react-final-form";

const CreateCart = props => {
	const { values } = useFormState();
	const [cart, setCart] = useState(null);
	const [orderId, setOrderId] = useState(null);
	const [cartId, setCartId] = useState(null);
	const [merchantId, setMerchantId] = useState(null);

	const { data: paymentMethods } = useGetList(
		"payment-methods",
		{ page: 1, perPage: 25 },
		{},
		{}
	);
	const [createCart] = useCreate(
		"carts",
		{
			checkinId: values.checkinId
		},
		{
			onSuccess: ({ data: { id } }) => {
				setCartId(id);
			}
		}
	);

	useGetOne(
		"stores",
		cart && cart.storeId,
		{
			enabled: !!cart,
			onSuccess: ({ data }) => {
				setMerchantId(data.merchantId);
			}
		},
		{}
	);

	const [
		createOrder,
		{ loading: createOrderLoading, error: createOrderError }
	] = useCreate(
		"orders",
		{
			cartId,
			provider: paymentMethods[values.paymentMethodId]?.provider,
			providerArgs: paymentMethods[values.paymentMethodId]?.providerArgs,
			deliveryMethod: values.deliveryMethod
		},
		{
			onSuccess: ({ data: { id: orderId } }) => {
				setOrderId(orderId);
			},
			onFailure: () => {
				setOrderId(null);
			}
		}
	);

	useEffect(() => {
		if (values.checkinId) createCart();
	}, [createCart]);

	const { refetch } = useGetOne(
		"carts",
		cartId,
		{
			enabled: cartId !== null,
			onSuccess: ({ data }) => {
				setCart(data);
			}
		},
		{}
	);

	const [createCartItem] = useCreate(
		"cart-items",
		{
			cartId,
			quantity: 1,
			productId: values.productId
		},
		{
			onSuccess: ({ data: { id } }) => {
				refetch();
			}
		}
	);

	const [updateCartItem] = useUpdate(
		"cart-items",
		null,
		{
			cartId
		},
		{},
		{
			onSuccess: () => {
				refetch();
			}
		}
	);

	const changeQuantity = useCallback(
		(cartItem, change) => {
			updateCartItem("cart-items", cartItem.cartItemId, {
				quantity: cartItem.quantity + change
			});
		},
		[updateCartItem]
	);

	return (
		<>
			<Grid
				container
				spacing={2}
				alignItems="stretch"
				justifyContent="space-between"
			>
				<Grid item xs={6} md={2}>
					{merchantId && (
						<ReferenceInput
							label="Product"
							source="productId"
							reference="products"
							allowEmpty={false}
							enableGetChoices={() => {
								console.log("asd");
							}}
							filter={{
								merchantId,
								storeId: cart.storeId
							}}
							sort={false}
						>
							<SelectInput label="name" optionText="name" />
						</ReferenceInput>
					)}
					<br />
					<Button
						variant="contained"
						color="primary"
						onClick={createCartItem}
						style={{ marginTop: 10 }}
					>
						Add item to cart
					</Button>
				</Grid>
				<Grid item xs={6} md={10} container>
					<Grid item lg={6}>
						<Table
							size="small"
							sx={{ minWidth: 650 }}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
									<TableCell width="30">QTY</TableCell>
									<TableCell width="200" align="left">
										Item
									</TableCell>
									<TableCell width="50" align="left">
										Price
									</TableCell>
									<TableCell width="50" align="left">
										Discount
									</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{cart?.items?.map(ci => (
									<TableRow>
										<TableCell>{ci.quantity}</TableCell>
										<TableCell>{ci.product.name}</TableCell>
										<TableCell>{ci.total}</TableCell>
										<TableCell>
											{ci.discountTotal}
										</TableCell>
										<TableCell align="right">
											<div style={{ display: "flex" }}>
												<Button
													onClick={() =>
														changeQuantity(ci, -1)
													}
												>
													-
												</Button>
												<Button
													onClick={() =>
														changeQuantity(ci, 1)
													}
												>
													+
												</Button>
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Grid>
					<Grid item lg={6}>
						<pre
							style={{
								marginTop: 15,
								marginLeft: 15,
								fontSize: 14,
								background: "#efefef",
								padding: 20
							}}
						>
							{JSON.stringify(cart, null, "  ")}
						</pre>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<SelectInput
						source="deliveryMethod"
						initialValue="TABLE_SERVICE"
						choices={[
							{ id: "TABLE_SERVICE", name: "Table Service" },
							{ id: "PICKUP", name: "Pickup" }
						]}
					/>
					<hr />
					<ReferenceInput
						label="Payment Method"
						source="paymentMethodId"
						initialValue="pm_1IMFgIBxaNBcEZyA6vgphP4L"
						reference="payment-methods"
						allowEmpty={false}
						sort={false}
					>
						<SelectInput label="Method" optionText="label" />
					</ReferenceInput>
					<br />
					{createOrderLoading ? (
						<span>Creating...</span>
					) : (
						<div>
							<div style={{ display: "flex" }}>
								<Button
									variant="contained"
									color="primary"
									onClick={createOrder}
									style={{ marginTop: 10 }}
								>
									Checkout & Pay
								</Button>
								{orderId && (
									<Button
										component={Link}
										color="primary"
										to={{
											pathname: `/orders/${orderId}/show`
										}}
										style={{
											marginTop: 15,
											marginLeft: 15
										}}
									>
										{orderId}
									</Button>
								)}
							</div>
							{!orderId && createOrderError && (
								<pre>
									{JSON.stringify(
										createOrderError,
										null,
										"  "
									)}
								</pre>
							)}
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default CreateCart;
