import { Card, CardHeader } from "@material-ui/core";
import { Check, Error, PlayArrow } from "@material-ui/icons";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import moment from "moment-timezone";
import React from "react";
import {
	BooleanField,
	DateField,
	FunctionField,
	ReferenceField,
	ReferenceManyField,
	TextField,
	useRecordContext,
	useShowController
} from "react-admin";

const OrderTicketDetails = props => {
	const { record } = useShowController(props);

	if (!record) return null;

	return (
		<>
			<Table
				sx={{ minWidth: 650 }}
				size="small"
				aria-label="a dense table"
			>
				<TableBody>
					<TableRow>
						<TableCell component="th" scope="row">
							ID
						</TableCell>
						<TableCell align="left">{record.id}</TableCell>
					</TableRow>
					{record.enablePrinter && (
						<TableRow>
							<TableCell component="th" scope="row">
								Printer IP
							</TableCell>
							<TableCell align="left">
								{record.printerIP}
							</TableCell>
						</TableRow>
					)}
					{record.enablePrinter && (
						<TableRow>
							<TableCell component="th" scope="row">
								Printer Type
							</TableCell>
							<TableCell align="left">
								{record.printerType}
							</TableCell>
						</TableRow>
					)}
					{record.enablePrinter && (
						<TableRow>
							<TableCell component="th" scope="row">
								Printer Port
							</TableCell>
							<TableCell align="left">
								{record.printerPort}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<br />
			<Table
				sx={{ minWidth: 650 }}
				size="small"
				aria-label="a dense table"
			>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="right">Quantity</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{record.orderItems.map((item, ix) => (
						<TableRow
							key={ix}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0
								}
							}}
						>
							<TableCell component="th" scope="row">
								{item.productName}
							</TableCell>
							<TableCell align="right">{item.quantity}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<br />
		</>
	);
};
const Elapsed = props => {
	const { source } = props;
	const orderTicket = useRecordContext(props);

	const elapsed = moment
		.duration(
			moment(orderTicket.timeDone).diff(moment(orderTicket.timeCreated))
		)
		.asMinutes()
		.toFixed(1);

	return `${elapsed}m`;
};

const rowStyle = selectedRow => record => {
	const theme = useTheme();
	let style = {};
	if (!record) {
		return style;
	}
	if (selectedRow && selectedRow === record.id) {
		style = {
			...style,
			backgroundColor: theme.palette.action.selected
		};
	}
	if (record.status === "DONE")
		return {
			...style,
			borderLeftColor: green[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	if (record.status === "CREATED")
		return {
			...style,
			borderLeftColor: orange[500],
			borderLeftWidth: 5,
			borderLeftStyle: "solid"
		};
	return style;
};

const StatusField = ({ record }) => {
	if (!record) return null;

	if (record.timeCompleted)
		return (
			<span style={{ color: "green" }}>
				<Check />
			</span>
		);
	if (record.timeError)
		return (
			<span style={{ color: "red" }}>
				<Error />
			</span>
		);
	if (record.timeStarted)
		return (
			<span style={{ color: "orange" }}>
				<PlayArrow />
			</span>
		);

	return "error";
};

const Component = ({ selectedRow, ...props }) => {
	return (
		<Card>
			<CardHeader title="Order Tickets"></CardHeader>
			<ReferenceManyField
				label="Order Tickets"
				reference="ordertickets"
				target="orderId"
				source="id"
				sort={false}
			>
				<ResponsiveDataGrid
					rowClick="show"
					primaryText={record =>
						`${record.stationName} - ${record.tableId}`
					}
					secondaryText={item => `${record.status}`}
					secondaryText={record =>
						`${moment(record.timeCreated).format(
							"YYYY-MM-DD HH:mm:ss"
						)} - ${moment(record.timeDone).format(
							"YYYY-MM-DD HH:mm:ss"
						)}`
					}
					empty={
						<EmptyCard text="No order tickets exists for this order" />
					}
					rowStyle={rowStyle(selectedRow)}
				>
					<TextField label="Station" source="stationName" />
					<DateField
						label="Created"
						source="timeCreated"
						showTime={true}
					/>
					<ReferenceField
						link="show"
						label="Export"
						source="id"
						target="orderTicketId"
						reference="orderticketexports"
					>
						<FunctionField
							render={record => <StatusField record={record} />}
						/>
					</ReferenceField>
					<DateField label="Done" source="timeDone" showTime={true} />
					<Elapsed label="Elapsed" />
					<TextField label="Table" source="tableId" />
					<TextField label="Status" source="status" />
					<BooleanField label="Printer" source="enablePrinter" />
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
