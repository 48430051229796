import React from "react";
import {
	ReferenceManyField,
	Datagrid,
	TextField,
	Pagination,
	Link,
	useRecordContext,
	BooleanField
} from "react-admin";
import { Card, Button, CardHeader, useMediaQuery } from "@material-ui/core";
import { Check, Clear } from "@material-ui/icons";
import { stringify } from "query-string";

const Component = ({ children, props }) => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader
				action={
					<>
						<Button
							component={Link}
							color="primary"
							to={{
								pathname: `/deals/create`,
								state: { record: { storeId: record.id } },
								search: stringify({
									storeId: record.id
								})
							}}
						>
							Create
						</Button>
					</>
				}
				title="Deals"
			></CardHeader>

			<ReferenceManyField
				label="Deals"
				reference="deals"
				target="storeId"
				sort={{ field: "priority", order: "ASC" }}
				perPage={10}
				pagination={<Pagination />}
			>
				<Datagrid {...props} rowClick="show">
					<TextField
						source="description"
						emptyText="-"
						sortable={true}
					/>
					<BooleanField source="active" />
					{!isXSmall && (
						<TextField
							source="category"
							emptyText="-"
							sortable={true}
						/>
					)}
					{!isXSmall && (
						<TextField
							source="priority"
							emptyText="-"
							sortable={true}
						/>
					)}
					{!isXSmall && (
						<TextField
							source="type"
							emptyText="-"
							sortable={true}
						/>
					)}
					{!isXSmall && <BooleanField source="firstTimeUsersOnly" />}
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
