import { useMediaQuery } from "@material-ui/core";

import React from "react";
import {
	List,
	Datagrid,
	TextField,
	TopToolbar,
	BooleanField,
	SimpleList
} from "react-admin";

const ListActions = props => <TopToolbar></TopToolbar>;

const DesktopGrid = props => (
	<Datagrid {...props} isRowSelectable={() => true} rowClick="show">
		<TextField source="id" emptyText="-" sortable={true} />
		<BooleanField source="isAvailable" />
		<TextField
			label="Message type"
			source="message.type"
			emptyText="-"
			sortable={true}
		/>
		<TextField
			label="Message name"
			source="message.name"
			emptyText="-"
			sortable={true}
		/>
		<TextField
			label="Message description"
			source="message.description"
			emptyText="-"
			sortable={true}
		/>
	</Datagrid>
);

const MobileGrid = props => (
	<SimpleList
		{...props}
		rowStyle={record =>
			record.isAvailable ? { color: "green" } : { color: "red" }
		}
		primaryText={record => record.id}
		tertiaryText={record =>
			!!record.isAvailable ? `Available` : `Disabled`
		}
		secondaryText={record =>
			record.message
				? `${record.message.type}: ${record.message.description}`
				: ``
		}
		linkType="show"
	/>
);
export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<List
			actions={<ListActions />}
			hasCreate={false}
			{...props}
			perPage={0}
			pagination={false}
			title="Payment Providers"
			sort={{ field: "id", order: "ASC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
