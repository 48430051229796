import { Typography } from "@material-ui/core";
import React from "react";
import { BooleanInput, required, SimpleForm, TextInput } from "react-admin";
import { useFormState } from "react-final-form";
import StripeConfig from "./StripeConfig";
import SwedbankPayConfig from "./SwedbankPayConfig";
import SwishConfig from "./SwishConfig";

const InsideForm = props => {
	const { values } = useFormState();

	return (
		<>
			<div>
				<TextInput
					style={{ marginTop: 0, minWidth: 400 }}
					source="name"
					helperText="Eg. Stureplansgruppen AB"
					margin="normal"
					validate={required("Name is required")}
				/>
			</div>
			<div>
				<BooleanInput
					source="externalProductSync"
					label="External Sync"
					helperText="External Sync should be enabled for all merchants dependent on a POS integration"
				/>
			</div>
			<div>
				<Typography variant="h6">Swish</Typography>
			</div>
			<div>
				<BooleanInput
					source="providers.swish"
					helperText={false}
					label="Swish"
				/>
			</div>
			<SwishConfig {...props} available={values.providers?.swish} />
			<div>
				<BooleanInput
					source="providers.swedbank_pay"
					helperText={false}
					label="Swedbank Pay"
				/>
			</div>
			{values.providers?.swedbank_pay && (
				<SwedbankPayConfig
					{...props}
					available={values.providers?.swedbank_pay}
				/>
			)}

			<Typography variant="h6">Card/Apple Pay</Typography>

			<div>
				<BooleanInput
					source="providers.stripe"
					helperText={false}
					label="Stripe"
				/>
			</div>
			<StripeConfig {...props} available={values.providers?.stripe} />
		</>
	);
};

const Form = props => {
	const validate = values => {
		const errors = {};

		if (values.providers?.stripe && !values.stripeId) {
			errors.stripeId = "Stripe Account Id is required";
		}

		if (values.providers?.swish && !values.swishId) {
			errors.swishId = "Swish Number is required";
		}

		if (values.providers?.swedbank_pay && !values.swedbankPayId) {
			errors.swedbankPayId = "Swedbank Pay Account Number is required";
		}

		if (values.providers?.swedbank_pay && values.providers?.swish) {
			errors.providers = {};
			errors.providers.swedbank_pay =
				"Swedbank Pay cannot be enabled at the same time as Swish (technical supplier)";

			errors.providers.swish =
				"Swish cannot be enabled at the same time as Swedbank Pay";
		}

		return errors;
	};

	const initialValues = {
		providers: {
			swish: false,
			stripe: false,
			swedbank_pay: false
		},
		fee: {
			swishFixed: 0,
			swishVariable: 0,

			stripeEuFixed: 0,
			stripeEuVariable: 0,

			stripeNonEuFixed: 0,
			stripeNonEuVariable: 0,

			swedbankPayAccountId: "1"
		}
	};

	return (
		<SimpleForm
			{...props}
			initialValues={initialValues}
			validate={validate}
			redirect="show"
			valid
		>
			<InsideForm {...props} />
		</SimpleForm>
	);
};

export default Form;
