import { Card } from "@material-ui/core";
import React from "react";
import {
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";

const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<TabbedShowLayout>
				<Tab label="Company">
					<TextField label="ID" source="id"></TextField>
					<TextField label="Name" source="name"></TextField>
					<TextField label="Org No" source="orgnr"></TextField>
					<TextField
						label="Address"
						source="address_street"
					></TextField>
					<TextField
						label="County"
						source="address_county"
					></TextField>
					<TextField label="City" source="address_city"></TextField>
					<TextField label="County" source="county"></TextField>
				</Tab>
				<Tab label="Details">
					<TextField source="county" />
					<TextField source="company_presentation" />
					<TextField source="google_maps" />
					<TextField source="link" />
					<TextField source="remarks" />
					<TextField source="has_remarks" />
					<TextField source="adress" />
					<TextField source="revenue" />
					<TextField source="revenue_historic" />
					<TextField source="year_founded" />
					<TextField source="phone_number" />
					<TextField source="assets" />
					<TextField source="profit_margin" />
					<TextField source="gross_net_margin" />
					<TextField source="SELECT orgnr" />
					<TextField source="name" />
					<TextField source="type" />
					<TextField source="company_message" />
					<TextField source="main_group" />
					<TextField source="sub_group" />
					<TextField source="county" />
					<TextField source="company_presentation" />
					<TextField source="link" />
					<TextField source="remarks" />
					<TextField source="has_remarks::boolean" />
					<TextField source="adress" />
					<TextField source="revenue::numeric" />
					<TextField source="revenue_historic::json" />
					<TextField source="year_founded::integer" />
					<TextField source="phone_number" />
					<TextField source="assets::numeric" />
					<TextField source="profit_margin" />
					<TextField source="gross_net_margin" />
				</Tab>
			</TabbedShowLayout>
		</Card>
	);
};

export default Component;
