import { Card, CardHeader, Grid } from "@material-ui/core";
import {
	Show,
	TextField,
	ReferenceField,
	BooleanField,
	FunctionField,
	ReferenceManyField,
	Datagrid
} from "ra-ui-materialui";
import { Check, Error, PlayArrow } from "@material-ui/icons";
import { TabbedShowLayout, Tab, useRecordContext } from "react-admin";
import { Title } from "./components";
import ActionButton from "components/buttons/ActionButton";

const Container = props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);

export default props => {
	return (
		<Show {...props} component={Container} title={<Title />}>
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Card>
						<TabbedShowLayout>
							<Tab label="Details">
								<TextField
									source="id"
									emptyText="-"
									sortable={true}
								/>
								<ReferenceField
									source="storeId"
									reference="stores"
									link="show"
								>
									<TextField source="name" emptyText="-" />
								</ReferenceField>

								<TextField
									source="description"
									emptyText="-"
									sortable={true}
								/>
								<TextField
									source="category"
									emptyText="-"
									sortable={true}
								/>
								<TextField
									source="priority"
									emptyText="-"
									sortable={true}
								/>
								<BooleanField source="active" />
								<TextField
									source="type"
									emptyText="-"
									sortable={true}
								/>
								<BooleanField source="firstTimeUsersOnly" />
							</Tab>
							<Tab label="Properties">
								<FunctionField
									addLabel={false}
									render={record => (
										<pre
											style={{
												background: "#fafafa",
												padding: 15
											}}
										>
											{JSON.stringify(
												record.properties,
												null,
												"  "
											)}
										</pre>
									)}
								/>
							</Tab>
						</TabbedShowLayout>
					</Card>
				</Grid>
			</>
		</Show>
	);
};
