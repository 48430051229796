import React from "react";
import { Create, SimpleForm, Toolbar } from "react-admin";
import CreateForm from "./components/Form";

const CustomToolbar = props => <Toolbar {...props}>&nbsp;</Toolbar>;
const CreateComponent = props => {
	return (
		<Create {...props}>
			<SimpleForm {...props} toolbar={<CustomToolbar />}>
				<CreateForm {...props}></CreateForm>
			</SimpleForm>
		</Create>
	);
};

export { CreateComponent };
