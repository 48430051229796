import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import { useMemo } from "react";
import {
	AutocompleteArrayInput,
	FilterList,
	FilterListItem,
	ReferenceArrayInput,
	TextInput,
	useListFilterContext
} from "react-admin";
import { Form } from "react-final-form";

const Card = withStyles(theme => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			order: -1, // display on the left rather than on the right of the list
			width: "20em",
			marginRight: "1em"
		},
		[theme.breakpoints.down("sm")]: {
			display: "none"
		}
	}
}))(MuiCard);

export default () => (
	<Card>
		<CardContent>
			<FilterList label="Price class">
				<FilterListItem
					label="Budget ($)"
					value={{ price_class_ai: "Budget ($)" }}
				/>
				<FilterListItem
					label="Undre mellanklass ($$)"
					value={{ price_class_ai: "Undre mellanklass ($$)" }}
				/>
				<FilterListItem
					label="Mellanklass ($$$)"
					value={{ price_class_ai: "Mellanklass ($$$)" }}
				/>
				<FilterListItem
					label="Hög ($$$$)"
					value={{ price_class_ai: "Hög ($$$$)" }}
				/>
			</FilterList>
			<FilterList label="Restaurant type">
				<FilterListItem label="Bar" value={{ types_ai: "Bar" }} />
				<FilterListItem
					label="Restaurang"
					value={{ types_ai: "Restaurang" }}
				/>
				<FilterListItem label="Café" value={{ types_ai: "Café" }} />
				<FilterListItem
					label="Nattklubb"
					value={{ types_ai: "Nattklubb" }}
				/>
			</FilterList>

			<FilterList label="Bar type">
				<FilterListItem
					label="Afterwork"
					value={{ bar_type_ai: "Afterwork" }}
				/>
				<FilterListItem
					label="Cocktailbar"
					value={{ bar_type_ai: "Cocktailbar" }}
				/>
				<FilterListItem
					label="Vinbar"
					value={{ bar_type_ai: "Vinbar" }}
				/>
				<FilterListItem
					label="Skybar"
					value={{ bar_type_ai: "Skybar" }}
				/>
				<FilterListItem
					label="Vacker utsikt"
					value={{ bar_type_ai: "Vacker utsikt" }}
				/>
				<FilterListItem
					label="Ölbar"
					value={{ bar_type_ai: "Ölbar" }}
				/>
				<FilterListItem label="Pub" value={{ bar_type_ai: "Pub" }} />
				<FilterListItem
					label="Livemusik"
					value={{ bar_type_ai: "Livemusik" }}
				/>
				<FilterListItem
					label="Sportbar"
					value={{ bar_type_ai: "Sportbar" }}
				/>
				<FilterListItem
					label="Aktivitet"
					value={{ bar_type_ai: "Aktivitet" }}
				/>
				<FilterListItem
					label="Irländsk pub"
					value={{ bar_type_ai: "Irländsk pub" }}
				/>
				<FilterListItem
					label="Whiskybar"
					value={{ bar_type_ai: "Whiskybar" }}
				/>
				<FilterListItem
					label="Budgetbar"
					value={{ bar_type_ai: "Budgetbar" }}
				/>
				<FilterListItem
					label="HBTQ-vänligt"
					value={{ bar_type_ai: "HBTQ-vänligt" }}
				/>
				<FilterListItem
					label="Champagnebar"
					value={{ bar_type_ai: "Champagnebar" }}
				/>
				<FilterListItem
					label="Rombar"
					value={{ bar_type_ai: "Rombar" }}
				/>
				<FilterListItem
					label="Karaokebar"
					value={{ bar_type_ai: "Karaokebar" }}
				/>
				<FilterListItem
					label="Ginbar"
					value={{ bar_type_ai: "Ginbar" }}
				/>
			</FilterList>

			<FilterList label="Rating">
				<FilterListItem
					label="✪"
					value={{ rating_gte: 1, rating_lte: 1.99 }}
				/>
				<FilterListItem
					label="✪✪"
					value={{ rating_gte: 2, rating_lte: 2.99 }}
				/>
				<FilterListItem
					label="✪✪✪"
					value={{ rating_gte: 3, rating_lte: 3.99 }}
				/>
				<FilterListItem
					label="✪✪✪✪"
					value={{ rating_gte: 4, rating_lte: 4.99 }}
				/>
				<FilterListItem label="✪✪✪✪✪" value={{ rating: 5 }} />
			</FilterList>
		</CardContent>
	</Card>
);
