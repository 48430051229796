import { createTheme } from "@material-ui/core/styles";

const headingFontFamily = ["Peak", "Helvetica Neue", "sans-serif"].join(",");

export default createTheme({
	palette: {
		primary: {
			main: "#41CE83"
		},
		secondary: {
			main: "#000000"
		},
		error: {
			main: "#FF735D"
		},
		success: {
			main: "#41CE83"
		}
	},
	typography: {
		fontFamily: "RedHatText, Roboto, Helvetica Neue, sans-serif",
		fontSize: 16,
		h1: { fontFamily: headingFontFamily },
		h2: { fontFamily: headingFontFamily },
		h3: { fontFamily: headingFontFamily },
		h4: { fontFamily: headingFontFamily },
		h5: { fontFamily: headingFontFamily },
		h6: { fontFamily: headingFontFamily }
	}
});
