import { Card } from "@material-ui/core";
import React from "react";
import {
	ArrayField,
	ChipField,
	FunctionField,
	ReferenceField,
	SimpleShowLayout,
	SingleFieldList,
	TextField
} from "react-admin";
import Header from "./Header";

const DeliveryMethodField = props => {
	const { record } = props;

	return record;
};

const Component = () => {
	return (
		<Card>
			<Header />

			<SimpleShowLayout>
				<TextField source="id" sortable={false} emptyText="-" />
				<ReferenceField
					source="merchantId"
					reference="merchants"
					link="show"
				>
					<TextField source="name" emptyText="-" />
				</ReferenceField>
				<TextField source="type" emptyText="-" />
				<TextField source="preferredApp" emptyText="-" />
				<ArrayField source="products">
					<SingleFieldList>
						<FunctionField
							render={record => (
								<ChipField
									record={{ name: record.type }}
									source="name"
								/>
							)}
						/>
					</SingleFieldList>
				</ArrayField>
				<ArrayField source="deliveryMethods">
					<SingleFieldList>
						<FunctionField
							render={record => (
								<ChipField
									record={{ name: record.type }}
									source="name"
								/>
							)}
						/>
					</SingleFieldList>
				</ArrayField>
				<ReferenceField
					source="priceListId"
					reference="price-lists"
					link="show"
				>
					<TextField source="name" sortable={false} emptyText="-" />
				</ReferenceField>
				<TextField source="orgNo" emptyText="-" />
				<TextField source="address" emptyText="-" />
				<TextField source="information" emptyText="-" />
			</SimpleShowLayout>
		</Card>
	);
};

export default Component;
