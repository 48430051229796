import React from "react";
import { Show } from "react-admin";
import { Grid } from "@material-ui/core";
import { TextField, ReferenceField, BooleanField } from "ra-ui-materialui";
import { Title, Orders, UserInfo, Container } from "./components";

export default props => {
	return (
		<Show {...props} actions={<></>} component={Container} title="Orders">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<UserInfo />
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<Orders />
				</Grid>
			</>
		</Show>
	);
};
