import { Admin, Layout, Resource } from "react-admin";
import { map } from "ramda";
import "styles/css/index.css";
import { theme } from "styles";
import { customApi } from "data";
import { authProvider } from "modules/auth";
import resources, { resourceIdPropNameMap } from "./resources";
import CustomMenu from "./Menu";

const CustomLayout = props => <Layout {...props} menu={CustomMenu} />;

function App() {
	const renderResources = map(r => <Resource {...r} key={r.name} />);

	return (
		<Admin
			{...{ theme, authProvider }}
			layout={CustomLayout}
			dataProvider={customApi(resourceIdPropNameMap)}
		>
			{renderResources(resources)}
		</Admin>
	);
}

export default App;
