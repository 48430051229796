import { Check, Error, PlayArrow } from "@material-ui/icons";
import React, { createElement } from "react";
import {
	ReferenceManyField,
	Datagrid,
	TextField,
	useRecordContext,
	DateField,
	SimpleList,
	NumberField
} from "react-admin";
import { Card, CardHeader, useMediaQuery } from "@material-ui/core";
import Moment from "moment-timezone";

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record =>
			"Bookkeeping date: " +
			Moment(record.bookkeepingDate)
				.tz("Europe/Stockholm")
				.format("YYYY-MM-DD")
		}
		secondaryText={record =>
			(record.total || "0") +
			" SEK, " +
			"Exported: " +
			(record.timeExported
				? Moment(record.timeExported)
						.tz("Europe/Stockholm")
						.format("YYYY-MM-DD HH:mm:SS")
				: "No")
		}
	></SimpleList>
);

const PostPanel = ({ id, record, resource, parentRecord }) => (
	<>
		<pre>{JSON.stringify(record, null, "  ")}</pre>
		<ReferenceManyField
			label="Fortnox reports"
			reference="fortnox-report-details"
			target="reportExportId"
			filter={{ merchantId: parentRecord.id }}
		>
			<Datagrid>
				<TextField label="Order ID" source="id" emptyText="-" />
				<DateField
					label="Transaction date"
					source="transactionDate"
					emptyText="-"
				/>
				<DateField
					label="Settled on merchant account"
					source="settledOnMerchantAccount"
					emptyText="-"
				/>

				<TextField label="Provider" source="provider" emptyText="-" />
				<NumberField
					label="Stripe payout amount"
					source="payoutAmount"
					emptyText="-"
				/>
				<NumberField
					label="Stripe payout fee"
					source="payoutFee"
					emptyText="-"
				/>
			</Datagrid>
		</ReferenceManyField>
	</>
);

const StatusField = props => {
	const record = useRecordContext(props);
	const error = record["error"];
	const exported = record["timeExported"];

	if (exported)
		return (
			<span style={{ color: "green" }}>
				<Check />
			</span>
		);

	if (error)
		return (
			<span style={{ color: "red" }}>
				<Error />
			</span>
		);

	return (
		<span style={{ color: "orange" }}>
			<PlayArrow />
		</span>
	);
};

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader title="Fortnox reports"></CardHeader>

			<ReferenceManyField
				label="Fortnox reports"
				reference="fortnox-reports"
				target="merchantId"
			>
				{isXSmall ? (
					<MobileGrid />
				) : (
					<Datagrid
						{...props}
						expand={data =>
							PostPanel({ ...data, parentRecord: record })
						}
					>
						<DateField
							label="Bookkeeping Date"
							source="bookkeepingDate"
							emptyText="-"
						/>

						<DateField
							label="Created"
							showTime={true}
							source="timeCreated"
							emptyText="-"
						/>
						<DateField
							label="Exported"
							showTime={true}
							source="timeExported"
							emptyText="-"
						/>
						<StatusField
							label="Status"
							source="error"
							emptyText="-"
						/>
					</Datagrid>
				)}
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
