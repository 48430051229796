import { Button, Card, CardHeader } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import rowStyle from "modules/orders/RowStyle";
import moment from "moment-timezone";
import { stringify } from "query-string";
import React from "react";
import {
	DateField,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";

const Component = props => {
	const record = useRecordContext();

	return (
		<Card>
			<CardHeader
				action={
					<Button
						component={Link}
						color="primary"
						to={{
							pathname: "/orders",
							search: stringify({
								filter: JSON.stringify({
									merchant_id_in: [record.id]
								}),
								order: "DESC",
								sort: "time_created"
							})
						}}
					>
						MORE
					</Button>
				}
				title="5 Latest orders"
			></CardHeader>

			<ReferenceManyField
				label="5 Latest orders"
				reference="orders"
				target="merchant_id"
				sort={{ field: "time_created", order: "DESC" }}
				perPage={5}
			>
				<ResponsiveDataGrid
					{...props}
					rowStyle={rowStyle(null)}
					empty={<EmptyCard text="No orders exists yet" />}
					rowClick="show"
					primaryText={record => record.store_name}
					secondaryText={record =>
						`${moment(record.time_created)
							.tz("Europe/Stockholm")
							.format("YYYY-MM-DD HH:mm:ss")} - ${record.id}`
					}
					tertiaryText={record => `${record.total}`}
				>
					<TextField source="id" emptyText="-" />
					<DateField showTime={true} source="time_created" />
					<TextField label="Total" source="total" />
				</ResponsiveDataGrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
