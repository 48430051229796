import generatePassword from "generate-password";
import React, { useCallback } from "react";
import {
	AutocompleteArrayInput,
	BooleanInput,
	email,
	PasswordInput,
	ReferenceArrayInput,
	regex,
	SimpleForm,
	TextInput,
	useMutation,
	useNotify,
	useRedirect
} from "react-admin";

const initialPassord = generatePassword.generate({
	length: 6,
	numbers: true
});
const validatePhoneNumber = regex(
	/^\+46[0-9]{9}$/,
	"Must be a Swedish mobile number +467XXXXXXXX"
);

const Form = props => {
	const [mutate] = useMutation();
	const notify = useNotify();
	const redirect = useRedirect();
	const save = useCallback(
		async values => {
			try {
				const response = await mutate(
					{
						type: "create",
						resource: "dashboard-users",
						payload: { data: values }
					},
					{ returnPromise: true }
				);
				redirect(`/dashboard-users/${response.data.id}/show`);
			} catch (error) {
				if (error.body.fields) {
					return error.body.fields;
				} else {
					notify(error.body.message);
				}
			}
		},
		[mutate]
	);

	return (
		<SimpleForm {...props} save={save}>
			<TextInput
				style={{ marginTop: 0 }}
				source="useremail"
				label="Email"
				margin="normal"
				type="email"
				validate={email()}
			/>

			<ReferenceArrayInput
				setFilter={() => ({})}
				sort={false}
				shouldRenderSuggestions={true}
				source="merchantIds"
				perPage={10000}
				label="Merchants access"
				reference="merchants"
			>
				<AutocompleteArrayInput source="id" />
			</ReferenceArrayInput>
			<TextInput
				style={{ marginTop: 0 }}
				source="phonenumber"
				label="Phone Number"
				margin="normal"
				validate={validatePhoneNumber}
			/>
			<PasswordInput
				label="Password"
				initiallyVisible={true}
				initialValue={initialPassord}
				source="password"
			/>
			<BooleanInput
				label="Send welcome email"
				defaultValue={true}
				helperText="Email will include link to a welcome page where user can set password"
				initalValue={false}
				source="welcomeemail"
			/>
		</SimpleForm>
	);
};

export default Form;
