import React from "react";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";

export default props => (
	<List {...props} perPage={25} sort={{ field: "merchantId", order: "ASC" }}>
		<Datagrid rowClick="show">
			<ReferenceField
				source="merchantId"
				reference="merchants"
				link="show"
			>
				<TextField source="name" emptyText="-" />
			</ReferenceField>
			<TextField source="id" sortable={false} emptyText="-" />
		</Datagrid>
	</List>
);
