import StoreIcon from "@material-ui/icons/Store";
import List from "./List";
import Show from "./Show";
import { CreateComponent, EditComponent } from "./CreateEdit";

export default {
	name: "stores",
	idPropName: "storeId",
	icon: StoreIcon,
	show: Show,
	create: CreateComponent,
	edit: EditComponent
};
