import { Button, Card, CardHeader, Grid } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import ResponsiveDataGrid from "components/ResponsiveDataGrid";
import rowStyle from "modules/orders/RowStyle";
import {
	BooleanField,
	ReferenceField,
	ReferenceManyField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";
import Container from "./components/Container";

const Component = props => {
	const record = useRecordContext(props);

	return (
		<>
			<Grid item xs={12} md={12} lg={12} xl={12}>
				<Card>
					<CardHeader
						action={
							<>
								<Button
									component={Link}
									color="primary"
									to={{
										pathname: `/checkins?filter=${encodeURIComponent(
											JSON.stringify({
												checkin_group_id: record.id
											})
										)}`
									}}
								>
									Details
								</Button>
								<Button
									component={Link}
									color="primary"
									to={{
										pathname: "/checkins/create",
										search: `?multiple=1&source=${JSON.stringify(
											{
												checkin_group_id: record.id
											}
										)}`
									}}
								>
									Bulk Create
								</Button>
							</>
						}
						title="Checkin Groups"
					></CardHeader>
					<TabbedShowLayout>
						<Tab label="Details">
							<TextField source="id" emptyText="-" />
							<ReferenceField
								link="show"
								source="store_id"
								reference="stores"
							>
								<TextField source="name" />
							</ReferenceField>
							<TextField source="checkin_label_prefix" />
							<BooleanField source="active" />
						</Tab>
					</TabbedShowLayout>
				</Card>
			</Grid>
			<Grid item xs={12} md={12} lg={12} xl={12}>
				<ReferenceManyField
					label="Checkins"
					reference="checkins"
					target="checkin_group_id"
					sort={{ field: "label", order: "ASC" }}
				>
					<Card>
						<CardHeader title="Checkins"></CardHeader>
						<ResponsiveDataGrid
							{...props}
							rowStyle={rowStyle(null)}
							empty={
								<EmptyCard text="No orders exists yet for this check in" />
							}
							rowClick={id => `/checkins/${id}/show`}
							primaryText={record => record.label}
							tertiaryText={record => `${record.total}`}
						>
							<TextField source="label" />
						</ResponsiveDataGrid>
					</Card>
				</ReferenceManyField>
			</Grid>
		</>
	);
};
export default props => {
	return (
		<Show {...props} component={Container}>
			<Component {...props} />
		</Show>
	);
};
