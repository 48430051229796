import { Card } from "@material-ui/core";
import React from "react";
import {
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";

const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<TabbedShowLayout>
				<Tab label="User">
					<TextField label="ID" source="id"></TextField>
					<TextField label="Email" source="email"></TextField>
				</Tab>
			</TabbedShowLayout>
		</Card>
	);
};

export default Component;
