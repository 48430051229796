import { Card } from "@material-ui/core";
import React from "react";
import { useRecordContext } from "react-admin";

const GoogleMapsField = props => {
	const { source } = props;
	const record = useRecordContext(props);

	if (!record[source]) return "N/A";
	const address = `https://maps.googleapis.com/maps/api/staticmap?center=${record.address}&zoom=13&size=572x330&maptype=roadmap
	&markers=color:red%7C${record.address}
	&key=AIzaSyADqgAsFC6rHVigZHxTHkSf9t7emU1XXyc`;
	return <img src={address} />;
};
const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<GoogleMapsField source="address" />
		</Card>
	);
};

export default Component;
