import { useMediaQuery } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import React from "react";
import {
	Datagrid,
	downloadCSV,
	List,
	SimpleList,
	TextField
} from "react-admin";

const DesktopGrid = props => (
	<Datagrid {...props} isRowSelectable={() => true} rowClick="show">
		<TextField source="name" emptyText="-" sortable={true} />
	</Datagrid>
);

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => record.name}
		linkType="show"
	/>
);

const exporter = posts => {
	const postsForExport = posts.map(post => {
		const { label, id } = post;

		return { Label: label };
	});
	jsonExport(
		postsForExport,
		{
			headers: ["Table", "QR"]
		},
		(err, csv) => {
			downloadCSV(csv, "checkins");
		}
	);
};

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

	return (
		<List
			hasCreate={false}
			{...props}
			perPage={0}
			pagination={false}
			exporter={exporter}
			title="Checkin Groups"
			sort={{ field: "name", order: "ASC" }}
		>
			{isSmall ? <MobileGrid /> : <DesktopGrid />}
		</List>
	);
};
