import { Card, CardHeader } from "@material-ui/core";
import { Create, Edit } from "ra-ui-materialui";
import React from "react";
import { Form } from "./components";
const CreateComponent = props => {
	return (
		<Card>
			<CardHeader title="Create Station"></CardHeader>
			<Create actions={false} {...props}>
				<Form {...props} />
			</Create>
		</Card>
	);
};

const EditComponent = props => {
	return (
		<Card>
			<CardHeader title="Edit"></CardHeader>
			<Edit mutationMode="pessimistic" actions={false} {...props}>
				<Form {...props} />
			</Edit>
		</Card>
	);
};

export { CreateComponent, EditComponent };
