import { PercentageField } from "modules/core/components";
import { anyPass, compose, defaultTo, gte, lte, startsWith } from "ramda";
import React from "react";
import { NumberInput, required, TextInput } from "react-admin";
import { floor, isNumber, validateInput, whenFloatString } from "utils";

const SwishConfig = ({ available }) => {
	if (!available) return null;

	return (
		<div>
			<div>
				<TextInput
					label="Swish number"
					source="swishId"
					helperText="Ex: 1235555555"
					margin="normal"
					validate={[
						validateInput(
							anyPass([
								compose(startsWith("123"), defaultTo("")),
								compose(startsWith("987"), defaultTo(""))
							]),
							"Must start with '123' or '987'"
						),
						validateInput(
							s => s.length === 10,
							"Must be 10 digits long"
						)
					]}
					style={{ minWidth: 300 }}
				/>
			</div>
			<div>
				<NumberInput
					label="Swish Fixed Fee"
					source="fee.swishFixed"
					helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
					parse={whenFloatString(compose(floor, parseFloat))}
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0 or greater"),
						required("Fixed transaction fee is required")
					]}
				/>
			</div>
			<div>
				<PercentageField
					label="Swish Variable Fee"
					source="fee.swishVariable"
					helperText="Amount in % of the transaction amount (0-5)"
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0% or greater"),
						validateInput(gte(0.05), "Must be 5% or less"),
						required("Variable transaction fee is required")
					]}
				/>
			</div>
		</div>
	);
};

export default SwishConfig;
