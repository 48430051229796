import { Grid } from "@material-ui/core";
import { Check, Error, PlayArrow } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import {
	BooleanField,
	DateField,
	FunctionField,
	ReferenceField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField
} from "react-admin";

const StatusField = ({ record }) => {
	if (!record) return null;

	if (record.timeCompleted)
		return (
			<span style={{ color: "green" }}>
				<Check />
			</span>
		);
	if (record.timeError)
		return (
			<span style={{ color: "red" }}>
				<Error />
			</span>
		);
	if (record.timeStarted)
		return (
			<span style={{ color: "orange" }}>
				<PlayArrow />
			</span>
		);

	return "error";
};

const OrderTicketDetails = ({ items }) => {
	if (!items) return null;

	return (
		<>
			<Table
				sx={{ minWidth: 650 }}
				size="small"
				aria-label="a dense table"
			>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="right">Quantity</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item, ix) => (
						<TableRow
							key={ix}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0
								}
							}}
						>
							<TableCell component="th" scope="row">
								{item.productName}
							</TableCell>
							<TableCell align="right">{item.quantity}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<br />
		</>
	);
};

export default props => {
	return (
		<Show {...props} actions={<></>} title="Order Ticket">
			<>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<TabbedShowLayout>
						<Tab label="Order Ticket">
							<TextField label="Station" source="stationName" />
							<DateField
								label="Created"
								source="timeCreated"
								showTime={true}
							/>
							<DateField
								label="Done"
								source="timeDone"
								showTime={true}
							/>
							<ReferenceField
								link="show"
								label="Order"
								source="orderId"
								target="order_id"
								reference="orders"
							>
								<TextField source="id" />
							</ReferenceField>
							<ReferenceField
								link="show"
								label="Export"
								source="id"
								target="orderTicketId"
								reference="orderticketexports"
							>
								<FunctionField
									label="Tab QR Code"
									render={record => (
										<StatusField record={record} />
									)}
								/>
							</ReferenceField>
							<TextField label="Table" source="tableId" />
							<TextField label="Status" source="status" />
							<BooleanField
								label="Printer"
								source="enablePrinter"
							/>
							<TextField label="Printer IP" source="printerIP" />
							<TextField
								label="Printer Port"
								source="printerPort"
							/>
						</Tab>
					</TabbedShowLayout>
				</Grid>
				<Grid item xs={12} md={12} lg={12} xl={12}>
					<FunctionField
						label="Items"
						render={record => (
							<OrderTicketDetails items={record.orderItems} />
						)}
					/>
				</Grid>
			</>
		</Show>
	);
};
