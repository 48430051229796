import BusinessIcon from "@material-ui/icons/Business";
import List from "./List";
import Show from "./Show";
import { CreateComponent, EditComponent } from "./CreateEdit";

export default {
	name: "merchants",
	idPropName: "merchantId",
	icon: BusinessIcon,
	list: List,
	show: Show,
	edit: EditComponent,
	create: CreateComponent
};
