import React from "react";
import { CardHeader } from "@material-ui/core";

const Component = ({ title }) => {
	return (
		<>
			<CardHeader title={title}></CardHeader>
		</>
	);
};

export default Component;
