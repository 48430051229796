const envs = {
	local: {
		name: "local",
		baseApiUrl: "http://localhost",
		apiUrl: "http://localhost:8008",
		functionsUrl: "https://europe-west2-way-development.cloudfunctions.net",
		dashboardUrl: "http://localhost:6001",
		firebase: {
			apiKey: "AIzaSyDhaVPwiN42DYlLp3GHfNydoAKLdbrN-ag",
			authDomain: "backoffice-771e5.firebaseapp.com",
			projectId: "backoffice-771e5",
			storageBucket: "backoffice-771e5.appspot.com",
			messagingSenderId: "474709736467",
			appId: "1:474709736467:web:d4a6ce06073d426c1893e6"
		}
	},
	development: {
		name: "development",
		baseApiUrl: "https://api.dev.passbuy.com",
		apiUrl: "https://backoffice-api.dev.passbuy.com",
		dashboardUrl: "https://dashboard.dev.passbuy.com",
		functionsUrl: "https://europe-west2-way-development.cloudfunctions.net",
		firebase: {
			apiKey: "AIzaSyDhaVPwiN42DYlLp3GHfNydoAKLdbrN-ag",
			authDomain: "backoffice-771e5.firebaseapp.com",
			projectId: "backoffice-771e5",
			storageBucket: "backoffice-771e5.appspot.com",
			messagingSenderId: "474709736467",
			appId: "1:474709736467:web:d4a6ce06073d426c1893e6"
		}
	},
	staging: {
		name: "staging",
		baseApiUrl: "https://api.staging.passbuy.com",
		dashboardUrl: "https://dashboard.staging.passbuy.com",
		apiUrl: "https://backoffice-api.staging.passbuy.com",
		functionsUrl: "https://europe-west2-way-staging-123.cloudfunctions.net",
		firebase: {
			apiKey: "AIzaSyDhaVPwiN42DYlLp3GHfNydoAKLdbrN-ag",
			authDomain: "backoffice-771e5.firebaseapp.com",
			projectId: "backoffice-771e5",
			storageBucket: "backoffice-771e5.appspot.com",
			messagingSenderId: "474709736467",
			appId: "1:474709736467:web:d4a6ce06073d426c1893e6"
		}
	},
	production: {
		name: "production",
		baseApiUrl: "https://api.passbuy.com",
		apiUrl: "https://backoffice-api.passbuy.com",
		dashboardUrl: "https://dashboard.passbuy.com",
		functionsUrl:
			"https://europe-west2-way-production-730b6.cloudfunctions.net",
		firebase: {
			apiKey: "AIzaSyDhaVPwiN42DYlLp3GHfNydoAKLdbrN-ag",
			authDomain: "backoffice-771e5.firebaseapp.com",
			projectId: "backoffice-771e5",
			storageBucket: "backoffice-771e5.appspot.com",
			messagingSenderId: "474709736467",
			appId: "1:474709736467:web:d4a6ce06073d426c1893e6"
		}
	}
};

export default envs[process.env.REACT_APP_ENVIRONMENT] || envs.local;
