import React from "react";
import { SelectInput, TextInput } from "react-admin";

const SwedbankPayConfig = ({ available }) => {
	if (!available) return null;

	return (
		<div>
			<div>
				<TextInput
					label="Swedbank Pay Account Number"
					source="swedbankPayId"
					helperText
					margin="normal"
					validate={[]}
					style={{ minWidth: 300 }}
				/>
			</div>
			<div>
				<SelectInput
					label="Swedbank Pay Account"
					style={{ minWidth: 300 }}
					helperText="Swedbank Pay Account that decides which debit model is used for the merchant. Should match the contract merchant has signed for."
					source="fee.swedbankPayAccountId"
					choices={[
						{
							id: "1",
							name:
								"#1 (1,50 kr/trans - 1 utbet. / vecka - 0 kr / månad)"
						},
						{
							id: "2",
							name:
								"#2 (2,00 kr/trans - 1 utbet. / vecka - 0 kr / månad)"
						},
						{
							id: "3",
							name:
								"#3 (2,50 kr/trans - 1 utbet. / vecka - 0 kr / månad)"
						},
						{
							id: "4",
							name:
								"#4 (2,50 kr/trans - 2 utbet. / vecka - 50 kr / månad)"
						}
					]}
				/>
			</div>
		</div>
	);
};

export default SwedbankPayConfig;
