import { Card } from "@material-ui/core";
import React from "react";
import {
	DateField,
	FunctionField,
	NumberField,
	ReferenceField,
	Tab,
	TabbedShowLayout,
	TextField,
	useRecordContext
} from "react-admin";

const Component = props => {
	const record = useRecordContext();
	return (
		<Card>
			<TabbedShowLayout>
				<Tab label="Order">
					<TextField label="ID" source="id"></TextField>
					<DateField
						label="Created"
						showTime={true}
						source="time_created"
					></DateField>
					<TextField label="Status" source="status"></TextField>
					<ReferenceField
						source="user_id"
						reference="users"
						link="show"
					>
						<TextField source="email" emptyText="-" />
					</ReferenceField>
					<ReferenceField
						source="checkin_id"
						label="Check in"
						reference="checkins"
						link="show"
					>
						<TextField source="label" emptyText="-" />
					</ReferenceField>
					<ReferenceField
						source="store_id"
						reference="stores"
						link="show"
					>
						<TextField source="name" emptyText="-" />
					</ReferenceField>

					<NumberField label="Total" source="total"></NumberField>
					<NumberField
						label="Tip"
						source="tip"
						emptyText="-"
					></NumberField>
				</Tab>
				<Tab label="Details">
					<ReferenceField
						source="merchant_id"
						reference="merchants"
						link="show"
					>
						<TextField source="name" emptyText="-" />
					</ReferenceField>
					<NumberField
						label="VAT total"
						source="vat_total"
					></NumberField>
					<NumberField
						label="Net total"
						source="net_total"
					></NumberField>
					<NumberField
						label="Discount"
						source="discount_total"
					></NumberField>
					<TextField label="Cart ID" source="cart_id"></TextField>
					<FunctionField
						label="Deals"
						render={record =>
							record.deals ? (
								<pre
									style={{
										background: "#fafafa",
										padding: 15
									}}
								>
									{JSON.stringify(record.deals, null, "  ")}
								</pre>
							) : (
								"-"
							)
						}
					/>
				</Tab>
			</TabbedShowLayout>
		</Card>
	);
};

export default Component;
