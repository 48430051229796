import Group from "@material-ui/icons/Group";

export default {
	name: "fortnox-reports",
	options: {
		label: "Fortnox reports"
	},
	idPropName: "reportExportId",
	icon: Group
};
