import { Card, CardHeader, Grid } from "@material-ui/core";
import {
	Show,
	SimpleShowLayout,
	TextField,
	ReferenceField,
	FunctionField,
	ReferenceManyField,
	Datagrid
} from "ra-ui-materialui";
import { useDispatch } from "react-redux";
import { TabbedShowLayout, Tab } from "react-admin";

import { Title } from "./components";

const Container = props => (
	<Grid
		{...props}
		container
		direction="row"
		justify="flex-start"
		spacing={3}
	/>
);

export default props => (
	<Show {...props} component={Container} title={<Title />}>
		<>
			<Grid item xs={12} md={7} lg={6} xl={6}>
				<Card>
					<TabbedShowLayout>
						<Tab label="Details">
							<ReferenceField
								source="integrationId"
								reference="integrations"
								link="show"
							>
								<TextField source="id" emptyText="-" />
							</ReferenceField>
							<TextField source="provider" emptyText="-" />
							<TextField source="type" emptyText="-" />
							<TextField source="timeStarted" emptyText="-" />
							<TextField source="timeCompleted" emptyText="-" />
							<TextField source="timeError" emptyText="-" />
							<TextField source="status" emptyText="-" />
							<FunctionField
								label="Error"
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.error,
											null,
											"  "
										)}
									</pre>
								)}
							/>
							<FunctionField
								label="Response"
								render={record => (
									<pre
										style={{
											background: "#fafafa",
											padding: 15
										}}
									>
										{JSON.stringify(
											record.response,
											null,
											"  "
										)}
									</pre>
								)}
							/>
						</Tab>
					</TabbedShowLayout>
				</Card>
			</Grid>
		</>
	</Show>
);
