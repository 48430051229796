import { Button, Card, CardHeader, useMediaQuery } from "@material-ui/core";
import EmptyCard from "components/EmptyCard";
import React from "react";
import {
	BooleanField,
	Datagrid,
	DateField,
	ReferenceManyField,
	SimpleList,
	TextField,
	useRecordContext
} from "react-admin";
import { Link } from "react-router-dom";

const MobileGrid = props => (
	<SimpleList
		{...props}
		primaryText={record => record.useremail}
		linkType="show"
	/>
);

const Component = props => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader
				action={
					<Button
						component={Link}
						color="primary"
						to={{
							pathname: "/dashboard-users/create",
							search: `?source=${JSON.stringify({
								merchantIds: [record.id]
							})}`
						}}
					>
						Create
					</Button>
				}
				title="Dashboard Users"
			></CardHeader>

			<ReferenceManyField
				label="Dashboard users"
				reference="dashboard-users"
				target="merchantId"
				sort={false}
			>
				{isXSmall ? (
					<MobileGrid />
				) : (
					<Datagrid
						empty={
							<EmptyCard text="No dashboard users exists yet" />
						}
						{...props}
						rowClick="show"
					>
						<TextField
							label="Email"
							source="useremail"
							emptyText="-"
						/>
						<DateField
							label="Create Date"
							showTime={true}
							source="createDate"
							emptyText="-"
						/>
						<DateField
							label="Last Sign in"
							showTime={true}
							source="lastSignInDate"
							emptyText="-"
						/>
						<BooleanField source="active" />
						<BooleanField source="emailVerified" />
						<BooleanField source="superuser" />
					</Datagrid>
				)}
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
