import { Box } from "@material-ui/core";
import React from "react";
import { BooleanInput, NumberInput, TextInput, required } from "react-admin";
import { useFormState } from "react-final-form";
import { validateInput } from "utils";
import arrayIntersects from "utils/arrays-intersect";

const inputValidations = (setting, values) => {
	return [
		...(setting.required ? [required(`${setting.label} is required`)] : []),
		...(Array.isArray(setting.requiredForFunctions) &&
		arrayIntersects(
			setting.requiredForFunctions,
			(values.functions || []).map(f => f.type)
		)
			? [
					required(
						`${
							setting.label
						} is required for ${setting.requiredForFunctions.join(
							", "
						)}`
					)
			  ]
			: [])
	];
};
const ConfigInput = ({ prefix, schema }) => {
	const { values } = useFormState();

	if (!values.provider) return null;
	if (!schema[values.provider]) return null;

	const providerConfigSchema = [
		...Object.keys(schema[values.provider][prefix] || {}).map(key => ({
			key,
			setting: schema[values.provider][prefix][key],
			source: `${prefix}.${key}`
		}))
	];

	return (
		<Box
			component="form"
			sx={{
				"& .MuiTextField-root": { m: 1, width: "25ch" }
			}}
			noValidate
			autoComplete="off"
		>
			{providerConfigSchema
				.filter(
					p =>
						!Array.isArray(p.setting.requiredForFunctions) ||
						arrayIntersects(
							p.setting.requiredForFunctions,
							(values.functions || []).map(f => f.type)
						)
				)
				.map(c => {
					if (c.setting.type === "text") {
						return (
							<TextInput
								source={c.source}
								margin="normal"
								validate={inputValidations(c.setting, values)}
								fullWidth={true}
								style={{ marginRight: 20 }}
								label={c.setting.label}
								helperText={c.setting.description}
							/>
						);
					} else if (c.setting.type === "number") {
						return (
							<NumberInput
								source={c.source}
								margin="normal"
								validate={inputValidations(c.setting, values)}
								fullWidth={true}
								style={{ marginRight: 20 }}
								label={c.setting.label}
								helperText={c.setting.description}
							/>
						);
					} else if (c.setting.type === "boolean") {
						return (
							<BooleanInput
								source={c.source}
								label={c.setting.label}
								helperText={c.setting.description}
							/>
						);
					}
				})}
		</Box>
	);
};

export default ConfigInput;
