import Group from "@material-ui/icons/Group";

export default {
	name: "stripe-payouts",
	options: {
		label: "Stripe payouts"
	},
	idPropName: "stripePayoutId",
	icon: Group
};
