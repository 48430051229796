import { Button } from "@material-ui/core";
import React from "react";
import {
	ArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput
} from "react-admin";

const Component = props => {
	const initialValues = state => {};

	const addRef = React.createRef();
	const itRef = React.createRef();

	const AddButton = props => (
		<Button ref={addRef} {...props}>
			Add
		</Button>
	);

	const redirect = (basePath, id, data) => {
		return `/checkingroups/${data.checkin_group_id}/show`;
	};

	return (
		<SimpleForm
			initialValues={initialValues}
			{...props}
			redirect={redirect}
		>
			<ReferenceInput
				label="Checkin Group"
				source="checkin_group_id"
				reference="checkingroups"
				sort={false}
				perPage={200}
			>
				<SelectInput disabled={true} label="Name" optionText="name" />
			</ReferenceInput>
			<ArrayInput label="Items" source="tables" ref={itRef}>
				<SimpleFormIterator addButton={<AddButton></AddButton>}>
					<TextInput
						label="Table"
						onKeyDown={e => {
							if (e.key === "Tab") addRef.current.click();
						}}
						source="label"
					/>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	);
};

export default Component;
