import { Card, CardHeader, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
	Datagrid,
	Pagination,
	ReferenceManyField,
	TextField,
	useRecordContext
} from "react-admin";

const Component = ({ children, props }) => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
	return (
		<Card>
			<CardHeader title="Checkin Groups"></CardHeader>

			<ReferenceManyField
				label="Checkin Groups"
				reference="checkingroups"
				target="store_id"
				perPage={10}
				sort={{ field: "name", order: "ASC" }}
				pagination={<Pagination />}
			>
				<Datagrid {...props} rowClick="show">
					<TextField source="name" emptyText="-" sortable={true} />
				</Datagrid>
			</ReferenceManyField>
		</Card>
	);
};

export default Component;
