import { Typography } from "@material-ui/core";
import { PercentageField } from "modules/core/components";
import { compose, defaultTo, gte, lte, startsWith } from "ramda";
import React from "react";
import { NumberInput, required as required, TextInput } from "react-admin";
import { floor, isNumber, validateInput, whenFloatString } from "utils";

const StripeConfig = ({ available }) => {
	if (!available) return null;

	return (
		<div>
			<div>
				<TextInput
					label="Stripe account id"
					source="stripeId"
					helperText="Ex: acct_xxxxxxxxxxxxxxxx"
					margin="normal"
					validate={[
						validateInput(
							compose(startsWith("acct_"), defaultTo("")),
							"Must start with 'acct_'"
						)
					]}
					style={{ minWidth: 300 }}
				/>
			</div>
			<div>
				<Typography variant="h7">Stripe EU Fees</Typography>
			</div>
			<div>
				<NumberInput
					label="Stripe EU Fixed Fee"
					source="fee.stripeEuFixed"
					helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
					parse={whenFloatString(compose(floor, parseFloat))}
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0 or greater"),
						required("Fixed transaction fee is required")
					]}
				/>
			</div>
			<div>
				<PercentageField
					label="Stripe EU Variable Fee"
					source="fee.stripeEuVariable"
					helperText="Amount in % of the transaction amount (0-5)"
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0% or greater"),
						validateInput(gte(0.05), "Must be 5% or less"),
						required("Variable transaction fee is required")
					]}
				/>
			</div>
			<div>
				<Typography variant="h7">Stripe NON-EU Fees</Typography>
			</div>
			<div>
				<NumberInput
					label="Stripe NON-EU Fixed Fee"
					source="fee.stripeNonEuFixed"
					helperText="Amount in SEK (0-n). Will never exceed the transaction amount on a per transaction basis."
					parse={whenFloatString(compose(floor, parseFloat))}
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0 or greater"),
						required("Fixed transaction fee is required")
					]}
				/>
			</div>
			<div>
				<PercentageField
					label="Stripe NON-EU Variable Fee"
					source="fee.stripeNonEuVariable"
					helperText="Amount in % of the transaction amount (0-5)"
					margin="normal"
					validate={[
						validateInput(isNumber, "Must be a number"),
						validateInput(lte(0), "Must be 0% or greater"),
						validateInput(gte(0.05), "Must be 5% or less"),
						required("Variable transaction fee is required")
					]}
				/>
			</div>
		</div>
	);
};

export default StripeConfig;
