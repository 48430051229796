import { Chip, Grid, useMediaQuery, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactStars from "react-rating-stars-component";
import Icon from "@material-ui/icons/List";
import React, { useState } from "react";
import {
	ArrayField,
	ChipField,
	Datagrid,
	Filter,
	List,
	NumberField,
	SingleFieldList,
	TextField,
	TextInput,
	TopToolbar,
	useListContext,
	useMutation,
	useNotify,
	useRecordContext,
	useRedirect,
	useResourceContext
} from "react-admin";
import FilterSidebar from "./FilterSidebar";
import rowStyle from "./RowStyle";
import { useTabContext } from "@material-ui/lab";

const useListStyles = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid"
	},
	headerCell: {
		padding: "6px 8px 6px 8px"
	},
	rowCell: {
		padding: "6px 8px 6px 8px"
	},
	comment: {
		maxWidth: "18em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	}
});

const TypesField = props => {
	const { source } = props;
	const record = useRecordContext(props);

	return (
		record[source]?.map((m, i) => (
			<Chip key={`${record[source]}-${i}`} label={m} />
		)) || ""
	);
};

const RatingStarField = props => {
	const { source } = props;

	const record = useRecordContext(props);

	return Number(record[source]) !== "NaN" ? (
		<ReactStars
			size={16}
			count={5}
			value={Number(record[source])}
			isHalf={true}
			char="✪"
			edit={false}
		/>
	) : (
		""
	);
};

const DesktopGrid = ({ selectedRow, ...props }) => {
	const classes = useListStyles();
	return (
		<Datagrid
			{...props}
			isRowSelectable={() => false}
			rowClick="show"
			rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell
			}}
		>
			<TextField source="name" emptyText="" />
			<TypesField source="types" />

			<TypesField source="bar_type" emptyText="" />
			<TypesField source="price_class" emptyText="" />
			<RatingStarField source="rating" emptyText="" />
		</Datagrid>
	);
};

const TopFilters = props => {
	return (
		<Filter {...props}>
			<TextInput
				autoFocus={true}
				label="Name"
				source="name_lke"
				alwaysOn
			/>
		</Filter>
	);
};

const Lead = props => {
	const context = useListContext(props);
	const notify = useNotify();
	const redirect = useRedirect();

	const restaurantType = context.filterValues?.types_ai;
	const barType = context.filterValues?.bar_type_ai;
	const rating = context.filterValues?.rating_gte;
	const [getLead, { loading }] = useMutation(
		{
			type: "getOne",
			resource: "get-lead",
			payload: {
				id: `${encodeURIComponent(restaurantType)}/${encodeURIComponent(
					barType
				)}/${encodeURIComponent(rating)}`
			}
		},
		{
			onSuccess: ({ data }) => {
				const id = data.id;
				if (id) {
					notify("💰🤑💰🤑💰🤑 Good luck! 💰🤑💰🤑💰🤑");
					redirect(`/restaurants/${id}/show`);
				} else {
					notify(`No leads 😭😭😭😭😭😭`, "warning");
				}
			},
			onFailure: error => notify(`Error ${error.message}`, "warning")
		}
	);
	const onInitiatePaymentButtonClick = () => {
		getLead();
	};

	return (
		<>
			<Button color="primary" onClick={onInitiatePaymentButtonClick}>
				I'm feeling lucky
			</Button>
		</>
	);
};

const ListActions = () => (
	<TopToolbar>
		<Lead />
	</TopToolbar>
);

export default props => {
	const isSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

	return (
		<List
			{...props}
			actions={<ListActions />}
			aside={<FilterSidebar />}
			filters={<TopFilters />}
			bulkActionButtons={false}
			sort={{ field: "name", order: "ASC" }}
			perPage={25}
		>
			<DesktopGrid />
		</List>
	);
};
